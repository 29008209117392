import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";


const state = {
    adminsData: [
        {
            firstName: 'Sean',
            lastName: 'Mackron',
            phoneNumber: '08028101227',
            email:  'seanmackron@gmail.com',
            status: 'Inactive'
        },
        {
            firstName: 'Jane',
            lastName: 'Septum',
            phoneNumber: '08034771122',
            email:  'janesept@yahoo.com',
            status: 'Active'
        },
        {
            firstName: 'Tony',
            lastName: 'Marcus',
            phoneNumber: '07037204132',
            email:  'tonymarc@bing.com',
            status: 'Active'
        },
        {
            firstName: 'Rita',
            lastName: 'Angel',
            phoneNumber: '07037812312',
            email:  'seanmackron@gmail.com',
            status: 'Active'
        },
        {
            firstName: 'Ajao',
            lastName: 'Samuel',
            phoneNumber: '08133445566',
            email:  'seanmackron@gmail.com',
            status: 'Inactive'
        },
        {
            firstName: 'Sean',
            lastName: 'Mackron',
            phoneNumber: '08034771122',
            email:  'seanmackron@gmail.com',
            status: 'Active'
        },
        {
            firstName: 'Ada',
            lastName: 'Okobi',
            phoneNumber: '08028101227',
            email:  'okobiada@gmail.com',
            status: 'Active'
        },
        {
            firstName: 'Sean',
            lastName: 'Mackron',
            phoneNumber: '07123427109',
            email:  'seanmackron@gmail.com',
            status: 'Active'
        },

    ],

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
