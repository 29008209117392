<template>
  <ValidationProvider name="Phone Number" rules="required" v-slot="{ classes, errors }">
    <div  :class="classes">
      <v-text-field  placeholder="800-000-0000"
                     solo hide-details v-model="phoneNumber" type="Number"
                     required
      >
        <template #prepend-inner>
          <v-menu
              top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
              >
                <img class="flag tw-mr-1" :src="selectedCountry.img" alt=""/> {{selectedCountry.code}}<v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list max-height="200" class="tw-bg-white">
              <v-list-item @click="handelSelectedCountry(item)"
                  v-for="(item, index) in countriesCode"
                  :key="index" class="tw-bg-white">
                <v-list-item-title class="tw-flex tw-flex-row"><img class="flag tw-mr-1" :src="item.img" alt=""/>  {{item.name}} &nbsp;  {{item.code}} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-text-field>
      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
// import nigeria_logo from "@/assets/nigeria-logo.png"
// import {getCountryCodes} from "@/services/api/APIService";

export default {
  name: "PhoneNumber",
  props:{
    phoneNumberExist: [String]
  },
  data(){
    return{
      selectedCountryCode: "234",
        selectedCountry:{
          code: "234",
          img:  "https://flagcdn.com/w320/ng.png"
        },
      countriesCode:[
          {
              code: "234",
              img: "https://flagcdn.com/w320/ng.png"
          },
          {
              code: "233",
              img: "https://flagcdn.com/w320/gh.png"
          },
      ],
      phoneNumber : "",


    }

  },
  watch:{
      phoneNumber: function (val){
              let code = this.selectedCountry.code
              if (this.phoneNumber.charAt(0) === "0" && val.length >10){
                  this.phoneNumber  = this.phoneNumber.replace('0',"")
              }
              this.$emit('getNumber',code+this.phoneNumber)
      },
      phoneNumberExist: function (val){
          if (val && val.length >6){
              this.phoneNumber = val.replace(this.getCountryCodeFromPhoneNumber(val),"")
          }
      },
      immediate: true,
      deep:true
  },
    methods:{
     // async getCountryCode(){
     //    await  getCountryCodes().then(res =>{
     //          this.countriesCode = []
     //          res.data.forEach(country =>{
     //              let code = {}
     //              code.img = country.flags.png
     //              code.code = country.idd.root+country.idd.suffixes[0]
     //              code.name = country.name.common
     //              this.countriesCode.push(code)
     //          })
     //      })
     //  },
        handelSelectedCountry(item){
          this.selectedCountry.code = item.code
            this.selectedCountry.img = item.img
        },

        getCountryCodeFromPhoneNumber(phoneNumber) {
            const countryCodeRegex = /(?:998|996|995|994|993|992|977|976|975|974|973|972|971|970|968|967|966|965|964|963|962|961|960|886|880|856|855|853|852|850|692|691|690|689|688|687|686|685|683|682|681|680|679|678|677|676|675|674|673|672|670|599|598|597|595|593|592|591|590|509|508|507|506|505|504|503|502|501|500|423|421|420|389|387|386|385|383|382|381|380|379|378|377|376|375|374|373|372|371|370|359|358|357|356|355|354|353|352|351|350|299|298|297|291|290|269|268|267|266|265|264|263|262|261|260|258|257|256|255|254|253|252|251|250|249|248|246|245|244|243|242|241|240|239|238|237|236|235|234|233|232|231|230|229|228|227|226|225|224|223|222|221|220|218|216|213|212|211|98|95|94|93|92|91|90|86|84|82|81|66|65|64|63|62|61|60|58|57|56|55|54|53|52|51|49|48|47|46|45|44\D?1624|44\D?1534|44\D?1481|44|43|41|40|39|36|34|33|32|31|30|27|20|7|1\D?939|1\D?876|1\D?869|1\D?868|1\D?849|1\D?829|1\D?809|1\D?787|1\D?784|1\D?767|1\D?758|1\D?721|1\D?684|1\D?671|1\D?670|1\D?664|1\D?649|1\D?473|1\D?441|1\D?345|1\D?340|1\D?284|1\D?268|1\D?264|1\D?246|1\D?242|1)\D?/;
            const countryCodeMatch = phoneNumber.match(countryCodeRegex);
            if (countryCodeMatch) {
                let getCountry = this.countriesCode.find(country => country.code === countryCodeMatch[0])
                this.selectedCountry.code = getCountry.code
                this.selectedCountry.img = getCountry.img
                return countryCodeMatch[0];
            }
            // If no match is found, return null or an appropriate value
            return "";
        }
    },
    mounted() {

    },
  async created() {

          if (this.phoneNumberExist){
              this.phoneNumber = this.phoneNumberExist.replace(this.getCountryCodeFromPhoneNumber(this.phoneNumberExist),"")
          }

  }
}
</script>

<style scoped lang="scss">
.flag{
  height: auto;
  width: 30px;
}
</style>