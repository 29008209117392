import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";


const state = {
    complaints: [
    {
        issueId: '#BO201422A',
        type: 'Booking',
        customerName: 'Sean Makers',
        status: 'pending',
        date: '12-04-2022',
        details: "You can override the default filtering used with search prop by supplying a function to the custom-filter prop. If you need to customize the filtering of a specific column, you can supply a function to the filter property on header items."    },
    {
        issueId: '#BO201422A',
        type: 'Booking',
        customerName: 'Sean Makers',
        status: 'resolved',
        date: '12-04-2022',
        details: "I have issues booking.When i try to book it brings up an error message. Please help"
    },
    {
        issueId: '#BO201422A',
        type: 'Booking',
        customerName: 'Sean Makers',
        status: 'pending',
        date: '12-04-2022',
        details: "You can override the default filtering used with search prop by supplying a function to the custom-filter prop. If you need to customize the filtering of a specific column, you can supply a function to the filter property on header items."
    },
    {
        issueId: '#BO201422A',
        type: 'Booking',
        customerName: 'Sean Makers',
        status: 'forwarded',
        date: '12-04-2022',
        details: 'I have issues booking. When i try to book it brings up an error message. Please help'
    },
        {
            issueId: '#BO201422A',
            type: 'Booking',
            customerName: 'Sean Makers',
            status: 'pending',
            date: '12-04-2022',
            details: "You can override the default filtering used with search prop by supplying a function to the custom-filter prop. If you need to customize the filtering of a specific column, you can supply a function to the filter property on header items."    },
        {
            issueId: '#BO201422A',
            type: 'Booking',
            customerName: 'Sean Makers',
            status: 'resolved',
            date: '12-04-2022',
            details: "I have issues booking.When i try to book it brings up an error message. Please help"
        },
        {
            issueId: '#BO201422A',
            type: 'Booking',
            customerName: 'Sean Makers',
            status: 'pending',
            date: '12-04-2022',
            details: "You can override the default filtering used with search prop by supplying a function to the custom-filter prop. If you need to customize the filtering of a specific column, you can supply a function to the filter property on header items."
        },
        {
            issueId: '#BO201422A',
            type: 'Booking',
            customerName: 'Sean Makers',
            status: 'forwarded',
            date: '12-04-2022',
            details: 'I have issues booking. When i try to book it brings up an error message. Please help'
        },{
            issueId: '#BO201422A',
            type: 'Booking',
            customerName: 'Sean Makers',
            status: 'pending',
            date: '12-04-2022',
            details: "You can override the default filtering used with search prop by supplying a function to the custom-filter prop. If you need to customize the filtering of a specific column, you can supply a function to the filter property on header items."    },
        {
            issueId: '#BO201422A',
            type: 'Booking',
            customerName: 'Sean Makers',
            status: 'resolved',
            date: '12-04-2022',
            details: "I have issues booking.When i try to book it brings up an error message. Please help"
        },
        {
            issueId: '#BO201422A',
            type: 'Booking',
            customerName: 'Sean Makers',
            status: 'pending',
            date: '12-04-2022',
            details: "You can override the default filtering used with search prop by supplying a function to the custom-filter prop. If you need to customize the filtering of a specific column, you can supply a function to the filter property on header items."
        },
        {
            issueId: '#BO201422A',
            type: 'Booking',
            customerName: 'Sean Makers',
            status: 'forwarded',
            date: '12-04-2022',
            details: 'I have issues booking. When i try to book it brings up an error message. Please help'
        },
  ]
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
