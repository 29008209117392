export default {
    bookings: state => state.bookings,
    booking: state => state.booking,
    oneWaySeats: state => state.oneWaySeats,
    oneWayTotalAmount: state => state.oneWayTotalAmount,
    oneWayTripAndTravellerDetails: state => state.oneWayTripAndTravellerDetails,
    oneWayBookingMinutesTimer: state => state.oneWayBookingMinutesTimer,
    oneWayBookingShowTimer: state => state.oneWayBookingShowTimer,
    roundTripDepartureSeats: state => state.roundTripDepartureSeats,
    roundTripReturnSeats: state => state.roundTripReturnSeats
    }