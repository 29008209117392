<template>
  <div class="security">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(handleChangePassword)">
      <div class="form tw-p-2">
        <v-col sm="12" class="password-input mb-md-4">
          <ValidationProvider name="Old Password" rules="required" v-slot="{ classes, errors }">
            <label class="label">Old Pasword</label>
            <div class="tw-pt-1 mb-1 mt-2" :class="classes">
              <v-text-field placeholder="********" solo v-model="updatePassword.oldPassword"
                            hide-details
                            :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="() => (value = !value)"
                            :type="value ? 'text' : 'password'" required class="tw-mt-2"

              ></v-text-field>

              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>

        <v-col sm="12" class="new-password-input mb-md-10">
          <ValidationProvider name="New Password" rules="required" v-slot="{ classes, errors }">
            <label class="label">New Password</label>
            <div class="tw-pt-1 mb-1 mt-2" :class="classes">
              <v-text-field
                  placeholder="********"
                  solo
                  hide-details
                  :append-icon="value2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value2 = !value2)"
                  :type="value2 ? 'text' : 'password'"
                  v-model="updatePassword.newPassword"
                  required
                  class="tw-mt-2"
              ></v-text-field>

              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>

        <v-col sm="12" class="password-input mb-md-4">
          <ValidationProvider
              name="Confirm Password"
              rules="required"
              v-slot="{ classes, errors }"
          >
            <label class="label">Confirm Password</label>
            <div class="tw-pt-1 mb-1 mt-2" :class="classes">
              <v-text-field
                  placeholder="********"
                  solo
                  hide-details
                  :append-icon="value2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value2 = !value2)"
                  :type="value2 ? 'text' : 'password'"
                  v-model="updatePassword.confirmPassword"
                  required
                  class="tw-mt-2"

              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>

        <div class="tw-w-full tw-flex tw-justify-end tw-py-5">
          <v-btn class="change-password"  type="submit" :loading="loading">Change Password</v-btn>
        </div>
      </div>

    </form>
  </validation-observer>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {changePassword, logout} from "@/services/api/AuthApiService";

export default {
  name: "LoginAndSecurity",
  data(){
    return{
       updatePassword:{},
      loading : false,
      value : false,
      value2: false
    }
  },
  computed:{
    ...mapGetters('mobileAgentData',['mobileAgentData'])
  },
  methods:{
    handleChangePassword(){
      this.loading = true
      let userData = JSON.parse(sessionStorage.getItem('userData'))
      if (this.updatePassword.newPassword === this.updatePassword.confirmPassword){
        this.updatePassword.email = userData.email
        changePassword(this.updatePassword).then(() => {
          this.$displaySnackbar({
            message : "Password changed successfully!",success:true
          })
          logout()
          this.loading = false
        }).catch(() => {
          this.$displaySnackbar({
            message : "An error occur while changing your password"
          })
          this.loading = false
        })
      }
      else {
        this.$displaySnackbar({
          message : "Password Mismatch"
        })
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.change-password{
  color: var(--white);
  background: #4f4f4f !important;
  font-family: var(--fontInter);

}
</style>