import Vue from "vue";
import VueRouter from "vue-router";

import CustomerDashboard from "../views/customer/CustomerDashboard.vue";
import CompanyDashboard from "../views/company/CompanyDashboard";
import SelectedCompany from "../views/company/SelectedCompany";
import ViewCustomer from "../views/customer/ViewCustomer.vue";
import AdminDashboard from "../views/admin/AdminDashboard.vue";
import NewAdmin from '../views/admin/NewAdmin.vue';
import ViewAdminDetails from '../views/admin/ViewAdminDetails.vue';
import Settings from "@/views/settings/SettingDashboard.vue";
import Issues from '../views/issues/IssueDashboard'
import {getUserData, isTokenValid} from "@/services/api/AuthApiService";

// import { components } from "vue/types/umd";
// import MainCompaniesView from '../views/company/MainCompaniesView '

Vue.use(VueRouter);

const routes = [
    { path: '*', redirect: '/404' },

    {
        path: "/new-booking",
        name: "MakeBooking",
        component:() => import("@/views/MakeBooking")
    },
    {
        path: "/404",
        name: "ErrorPage",
        component: () => import("@/components/reusables/ErrorPage.vue")
    },
  {
    path: "/admin",
    name: "DashboardView",
    component: () => import("@/views/DashboardView"),
    redirect: {
      name: "MainDashboard",
    },
    children: [
      {
        path: "",
        name: "MainDashboard",
        component: () => import("@/views/dashboard/MainDashboard"),
          meta: {
              userType: "admin"
          },
      },
      {
        path: "/admin/booking",
        name: "BookingDashboard",
        component: () => import("@/views/bookingFlow/BookingDashboard"),
          meta: {
              userType: "admin"
          },
      },

      {
        path: "/admin/selected-company",
        name: "SelectedCompany",
        component: SelectedCompany,
          meta: {
              userType: "admin"
          },
      },
      {
        path: "/admin/company",
        name: "CompanyDashboard",
        component: CompanyDashboard,
          meta: {
              userType: "admin"
          },
      },
        {
            path:"/admin/admins",
            name: "CorporateAgentAdmins",
            component: AdminDashboard,
            meta: {
                userType: "admin"
            },
        },
        {
            path: '/admin/selected-booking',
            name: 'SelectedBooking',
            component: ()=> import("@/views/bookingFlow/SelectedBooking"),
            meta: {
                userType: "admin"
            },
        } ,
        {
          path: '/admin/admin-details',
          name: 'ViewAdminDetails',
          component: ViewAdminDetails,
            meta: {
                userType: "admin"
            },
      },
        {
            path: '/admin/new-admin',
            name: 'NewAdmin',
            component: NewAdmin
        },
      {
        path: '/admin/issues',
        name: 'IssueDashboard',
        component: Issues,
          meta: {
              userType: "admin"
          },
      },
        {
            path: '/admin/settings',
            name: 'SettingDashboard',
            component: Settings,
            meta: {
                userType: "admin"
            },
        },
        {
            path:"/view-customer",
            name:"ViewCustomer",
            component: ViewCustomer,
            meta: {
                userType: "admin"
            },
        },
        {
            path:"/admin/customer",
            name: "CustomerDashboard",
            component: CustomerDashboard,
            meta: {
                userType: "admin"
            },
        },

        
        {
            path: '/admin/edit-booking',
            name: 'EditBooking',
            component: ()=> import("@/views/bookingFlow/EditBooking"),
            meta: {
                userType: "admin"
            },
        },
        {
            path: '/admin/wallet',
            name: 'WalletDashboard',
            component: ()=> import("@/views/wallet/WalletDashboard"),
            meta: {
                userType: "admin"
            },
        },
        {
            path:"/admin/trips",
            name: "TripDashboard",
            component: ()=> import("@/views/trips/TripDashboard"),
            meta: {
                userType: "admin"
            },
        },
        {
            path:"/admin/view-trip",
            name: "ViewSelectedTrip",
            component: ()=> import("@/views/trips/ViewSelectedTrip"),
            meta: {
                userType: "admin"
            },
        },
    ]
  },
    {
        path: "/",
        name: "Login",
        component: ()=> import("@/views/LoginView")
    },
    {
        path: "/select-company",
        name: "SelectCompany",
        component: ()=> import("@/views/SelectCompany")
    },

    {
        path: "/round-trips",
        name: "RoundTrips",
        component: () => import("@/views/roundTripBooking/ReturnTrips")
    },
    {
        path: "/round-trip-select-seats",
        name: "RoundTripSelectSeats",
        component: ()=> import("@/views/roundTripBooking/SelectSeatsForReturnTrip")
    },
    {
        path: "/round-trip-traveler-details",
        name: "RoundTripTravelerDetails",
        component: ()=> import("@/views/roundTripBooking/ReturnTripTravellerDetails")
    },
    {
        path: "/round-trip-check-out",
        name: "RoundTripCheckout",
        component: ()=> import("@/views/roundTripBooking/CheckOutView")
    },
    {
        path: "/one-way-trips",
        name: "OneWayTrips",
        component: ()=> import("@/views/OneWayBooking/SelectBus")
    },
    {
        path: "/one-way-select-seats",
        name: "OneWaySelectSeats",
        component : () => import("@/views/OneWayBooking/SelectSeats")
    },
    {
        path: "/one-way-passengers-details",
        name: "OneWayInputDetails",
        component: ()=> import("@/views/OneWayBooking/InputDetails")
    },
    {
        path: "/one-way-check-out",
        name: "OneWayCheckout",
        component: () => import("@/views/OneWayBooking/CheckOut")
    }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

    let token = to.query.access_token
    if (token){
        getUserData(token)
        router.replace({query:null}).then()
        token = null
        router.push({name: 'MainDashboard'}).then(() => {})

    }

    let userData = JSON.parse(sessionStorage.getItem('userData'))
    if (to.meta.userType) {
        if (!userData) {
            next({
                name: 'Login'
            })
        }
        else {
            isTokenValid(userData.exp)
        }
    }
    next();
})

export default router;
