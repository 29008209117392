<template>
  <div>
<div class="wrapper">
  <div class="tw-flex tw-flex-wrap tw-mb-10">
    <div class="general">
      <div class="mobile-lines left-line-admins-green"></div>

      <v-card class="total-trips tw-w-full" flat @click="$router.push({name: 'NewAdmin'})">

        <v-col cols="3">
          <img src="@/assets/add-an-admin.svg" alt="Add a booking icon" class="mt-2"/>
        </v-col>
        <v-col cols="9" class="ml-2 space">
          <p class="heading mt-2 mb-1">New</p>
          <p class="subheading"> Add an Admin</p>
        </v-col>

      </v-card>
    </div>
    <div class="general">
      <div class="mobile-lines left-line-admins-green"></div>
      <v-card class="total-trips tw-w-full" flat >
        <v-col cols="3">
          <img src="@/assets/all-admins.svg" alt="Total booking icon" class="mt-2"/>
        </v-col>
        <v-col cols="9" class="ml-2 space">
          <p class="heading mt-2 mb-1">{{ noOfAdmins }}</p>
          <p class="subheading"> Total Admins</p>
        </v-col>

      </v-card>
    </div>
  </div>

  <div class="admin-content-details py-5">
        <h1 class="heading ml-3 my-3" style="font-size: 18px">All Admins</h1>
        <v-data-table
            :headers="headers" :items="adminsData" mobile-breakpoint="0"
            :hide-default-footer="adminsData.length <= 10" class="tw-cursor-pointer" @click:row="handleRowClick">

          <template #header.firstName>
            <span class="column-header">First Name</span>
          </template>
          <template v-slot:[`item.firstName`] = "{item}">
            <span class="table-data ">{{item.firstName}}</span>
          </template>

          <template #header.lastName>
            <span class="column-header">Last Name</span>
          </template>
          <template v-slot: [`item.lastName`]="{item}">
            <span class="table-data ">{{item.lastName}}</span>
          </template>

          <template #header.phoneNumber>
            <span class="column-header"> Phone Number</span>
          </template>
          <template v-slot: [`item.phoneNumber`]="{item}">
            <span class="table-data ">{{item.phoneNumber}}</span>
          </template>

          <template #header.email>
            <span class="column-header">Email</span>
          </template>
          <template v-slot:item.email="{item}">
            <span class="table-data row"> {{item.email}} </span>
          </template>

          <template #header.status>
            <span class="column-header"> Status</span>
          </template>
          <template v-slot:item.status="{item}">
            <span class="table-data ">{{item.status}}</span>
          </template>
          <template #header.actions>
            <span class="column-header"></span>
          </template>
          <template v-slot:item.actions="{item}">
            <v-menu offset-y bottom left min-width="150px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed class="btn-dot" >
                <v-icon color="var(--brown)" text v-bind="attrs" v-on="on" >mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link @click="handleRowClick(item)">
                  <v-list-item-title class="row">
                    <used-icons name="edit" class="mx-2"></used-icons>
                    <p class="cta-text">Edit Admin</p>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click="getFirstName(item.firstName)">
                  <v-list-item-title class="row">
                    <used-icons name="cancel"  class="mx-2"/>
                    <p class="cta-text">Restrict Access</p>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          
          </v-data-table>
      </div>

    </div>
    <all-modals
        :width="290"
        :header="'Restrict ' + firstName"
        :description="restrictUser"
        :dialog="showModal"
        @closeModal="handleShowModal"
        @action="handleRestriction"  />
</div>
</template>

<script>
import {mapGetters} from "vuex";
import UsedIcons from "@/components/reusables/UsedIcons";
import AllModals from "@/components/reusables/AllModals";
export default {
  name: "AdminDashboard",
  components: {UsedIcons, AllModals},
  data () {
      return {
        headers: [
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'firstName',
          },
          { text: '',
            value: 'lastName',
            sortable: false
          },
          { text: '',
            value: 'phoneNumber',
            sortable: false,
          },
          { text: '',
            value: 'email',
            sortable: false,
          },
          { text: '',
            value: 'status',
            sortable: false,
          },
          { text: '',
            value: 'actions',
            sortable: false,
            align: 'end'
          },

        ],
        noOfAdmins: "",
        showModal : false,
        firstName: "",
        restrictUser: "User would no longer be able to access this platform."
      }
    },
  computed:{
    ...mapGetters('adminStore', ['adminsData']),
  },
  mounted() {
    this.noOfAdmins = this.adminsData.length
  },
  methods:{
    handleRowClick(adminsData) {
      this.$store.dispatch('adminStore/selectedAdmin', adminsData)
      this.$router.push({
        name: 'ViewAdminDetails'
      })
    },

    handleShowModal(){
      this.showModal = !this.showModal
    },
    handleRestriction(userName){
      console.log(userName)
    },
    getFirstName(firstName){
      this.firstName = firstName
      this.handleShowModal()
    }

  },
  }
</script>

<style lang="scss" scoped>
.general{
  display: flex;
  width: 250px ;
  height: 100px;
  font-family: var(--fontOne);
  color: var(--brown);
  margin-right: 20px;
  @media (max-width: 1560px) {
    margin: 10px 10px 10px 0;
  }
  @media (max-width:1024px) {
    width: 45%;
  }
  @media (max-width:576px) {
    width: 100%;
    height: 80px;
  };
}
.general > .v-card{
  padding: 20px;
  background: var(--offWhite);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px !important;
  display: flex;
  @media (max-width:576px) {

    padding: 7PX 15px;
  };
}

.heading {
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  color: var(--brown);
  font-family: var(--fontInter);
  @media (max-width:768px) {
    font-size: 15px;
  }
}

.space {
  @media (max-width:650px) {
    margin-left: 20px;
  }
  @media (max-width:576px) {
    margin-left: 0;
  }
}

.subheading {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--brown);
  font-family: var(--fontInter);
}
.grouped-cards {
  background-color: var(--white);
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  padding: 40px;
  @media (max-width:820px) {
    padding: 25px;
  }
}
.admin-content-details{
   background: var(--offWhite);
   border-radius: 10px;
  padding: 20px;
}

.cta-text {
  font-family: var(--fontInter);
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  cursor: pointer;
}

.mobile-lines {
  width: 5px;
  height: 100%;
  margin-right: 15px;
  border-radius: 7px;
  display: none;
  @media (max-width:1024px) {
    display: block;
  }
}
:deep(.theme--light.v-data-table v-data-table__wrapper table thead tr th),
.column-header{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--brown);
  text-align: left;
  white-space: nowrap;
}

:deep(.theme--light.v-data-table.v-data-table__wrapper table tr) {
  height: 60px;
}

:deep(.v-data-table  .v-data-table__wrapper  table  tbody  tr  td) {
  font-family: 'Inter';
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  color: var(--brown);
}

:deep(.theme--light.v-data-table) {
  background-color: var(--offWhite);
}

:deep(.v-data-table .v-data-table__wrapper table tbody tr td) {
  white-space: nowrap !important;
}
:deep(.v-menu__content){
  width: 300px;
}
.btn-dot{
  background-color: transparent !important;
}
</style>