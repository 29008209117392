<template>
  <div class="profile" >
    <v-tabs background-color="#fdfffc" hide-slider color="#4f4f4f"  v-model="tabs" class="my-7"  >
      <v-tab>Profile</v-tab>
      <v-tab>Address</v-tab>
      <v-tab>Account Detail</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs" class="ml-5">
      <v-tab-item >
        <edit-onboarding-brand/>
      </v-tab-item>
      <v-tab-item >
        <address-detail/>
      </v-tab-item>
      <v-tab-item >
        <account-detail/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import EditOnboardingBrand from "@/views/settings/EditOnboardingBrand";
import AccountDetail from "@/views/settings/AccountDetail";
import AddressDetail from "@/views/settings/AddressDetail";
export default {
  name: "ProfileTab",
  components: {AddressDetail, AccountDetail, EditOnboardingBrand},
  data(){
    return{
      tabs:null
    }
  }
}
</script>

<style scoped lang="scss">
.v-tab {
  font-family:var(--fontInter);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 19px;
  text-transform: none;
}

</style>