
export default {
    SET_TRANSPORT_COMPANIES: (state, payload) => {
        state.transportCompanies = payload;
    },

    TOGGLE_SUBSCRIBE: (state, payload) =>{
        state.transportCompanies.forEach(company =>{
            if(company.id === payload){
                company.subscribed = !company.subscribed
            }
        })
    }

};
