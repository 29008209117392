<template>
  <div>
    <div class="issues-card p-8">
      <p class="issue-heading ml-5 py-7">User Complaints</p>
      <v-data-table
        :headers="headers"
        :items="complaints"
        mobile-breakpoint="0"
        :hide-default-footer="complaints.length <= 10"
        class="tw-cursor-pointer"
        @click:row="handleRowClick(issue)"
      >
        <template #header.issueId>
          <span class="column-header">Issue ID</span>
        </template>
        <template v-slot:[`item.issueId`]="{ item }">
          <span class="table-data">{{ item.issueId }}</span>
        </template>

        <template #header.type>
          <span class="column-header">Type</span>
        </template>
        <template v-slot: [`item.type`]="{item}">
          <span class="table-data">{{ item.type }}</span>
        </template>

        <template #header.customer>
          <span class="column-header"> Customer</span>
        </template>
        <template v-slot:item.customer="{ item }">
          <span class="table-data" style="white-space: nowrap">{{ item.customerName }}</span>
        </template>
        <template #header.status>
          <span class="column-header">Status</span>
        </template>
        <template v-slot:item.status="{ item }">
          <span class="table-data status">
            <v-chip
              color="#16BB8B"
              text-color="var(--white)"
              v-if="item.status === 'resolved'"
              >{{ item.status }}</v-chip
            >
            <v-chip
              color="#BA68C8"
              text-color="var(--white)"
              v-if="item.status === 'pending'"
              >{{ item.status }}</v-chip
            >
            <v-chip
              color="#AFA259"
              text-color="var(--white)"
              v-if="item.status === 'forwarded'"
              >{{ item.status }}</v-chip
            >
          </span>
        </template>
        <template #header.date>
          <span class="column-header">Date</span>
        </template>
        <template v-slot:item.date="{ item }">
          <span class="table-data" style="white-space: nowrap">{{ item.date }}</span>
        </template>
        <template #header.complaintDetails>
          <span class="column-header">Complaint Details</span>
        </template>
        <template v-slot:item.complaintDetails="{ item }">
          <span class="table-data" >{{item.details}} </span>
        </template>
<!--        .substring(0,20)-->
        <template #header.actions>
          <span class="column-header"></span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="#004aad" text v-bind="attrs" v-on="on"
                >mdi-dots-horizontal
              </v-icon>
            </template>
            <v-list>
              <v-list-item link @click="handleRowClick(item)">
                <v-list-item-title class="tw-flex">
                  <used-icons class="mr-4" name="resolve-issue" />
                  <p class="cta-text">Resolve Issue</p>
                </v-list-item-title>
              </v-list-item>
              <v-list-item link @click="forwardIssue(item)">
                <v-list-item-title class="tw-flex">
                  <used-icons class="mr-4" name="forward-issue" />
                  <p class="cta-text">Forward Issue</p>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
    <ALlModal
        @closeModal="exitModal"
        :dialog="showModal" section="view-issue"
        id="hello"
        width = 700 />
    <ALlModal
        @closeModal="exitModal"
        :dialog="showForwardIssueModal" section="forward-issue"
        header="Forward Issue?"
        description="This issue would be forwarded to RoadPadi to resolve. Are you sure you want to go ahead with it?"
        @action="handleIssueForwarding"/>

  </div>
</template>

<script>
  import ALlModal from "@/components/reusables/AllModals"
import UsedIcons from "@/components/reusables/UsedIcons.vue";
import { mapGetters } from "vuex";
export default {
  name: "IssueDashboard",
  components: { UsedIcons, ALlModal },

  data() {
    return {
      headers: [
        {
          text: "",
          value: "issueId",
          sortable: false,
        },
        {
          text: "",
          value: "type",
          align: "start",
          sortable: false,
        },
        {
          text: "",
          value: "customer",
          align: "start",
          sortable: false,
        },
        {
          text: "",
          value: "status",
          align: "start",
          sortable: false,
        },
        {
          text: "",
          value: "date",
        },
        {
          text: "",
          value: "complaintDetails",
          align: "start",
          sortable: false,
        },

        {
          text: "",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      showModal: false,
      showForwardIssueModal: false,
      width: 500,
      issue:"hello",
      userName: 'Sean Makers',
      complaint: 'I have issues booking. when i try to book it brings up an error message. Please help'
    };
  },

  computed: {
    ...mapGetters("issueStore", ["complaints"]),
  },

  methods: {
    handleRowClick(complaints){
      this.$store.dispatch('issueStore/customerComplaints', complaints)
      this.showModal = true;
    },
    exitModal(){
      this.showModal=false
      this.showForwardIssueModal= false
    },
    forwardIssue(complaints){
      this.$store.dispatch('issueStore/customerComplaints', complaints)
      this.showForwardIssueModal=true
    },
    handleIssueForwarding(){
      console.log("hello")
    }
  },
};
</script>

<style lang="scss" scoped>

.issues-card, :deep(.theme--light.v-data-table) {
  background-color: var(--offWhite);
  border-radius: 10px;
}
.issue-heading{
  font-family: var(--fontInter);
  color: var(--brown);
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.table-data {
  font-family: var(--fontInter);
  font-weight: 300;
  font-size: 14px;
  padding-top: 1rem !important;
  line-height: 34px;
  color: var(--brown);

}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td) {
  font-family: "Inter";
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  color: var(--brown);
}

.cta-text {
  font-family: var(--fontInter);
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  cursor: pointer;
}

.menu-icon {
  font-size: 15px;
}

.status-icon {
  font-size: 13px;
}

.status {
  text-transform: capitalize;
  color: var(--white);
  height: 70px !important;
}


:deep(.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > th),
.column-header {
  font-family: var(--fontInter);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--brown);
  white-space: nowrap;
}

.v-chip {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px !important;
}

</style>
