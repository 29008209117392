<template>
  <div class="wrapper">
    <v-btn class="left tw-cursor-pointer " text @click="routeBack">
      <v-icon color="var(--primaryColor)">mdi-arrow-left</v-icon>
      <span> &nbsp; All Customer</span>
    </v-btn>
    <div class="display-center-with-flex">
      <div class="content">
      <div class="customer">
        <h3 class="section-title">Customer Details</h3>
        <div class="customer-details">
          <v-col lg="6">
            <div class="tw-flex">
              <v-col class="question">First Name:</v-col>
              <v-col class="answer">{{customer.firstName}}</v-col>
            </div>
            <div class="tw-flex">
              <v-col class="question">Last Name:</v-col>
              <v-col class="answer">{{customer.lastName}}</v-col>
            </div>
          </v-col>
          <v-col lg="6" class="">
          <div class="tw-flex">
            <v-col class="question">Email :</v-col>
            <v-col class="answer">{{customer.email}}</v-col>
          </div>
          <div class="tw-flex">
            <v-col class="question">Phone :</v-col>
            <v-col class="answer">{{customer.phoneNumber}}</v-col>
          </div>
          </v-col>
      </div>
      </div>

     <div class="nok">
       <h3 class="section-title">Next of kin Details</h3>
       <div class="next-of-kin-details">
       <v-col lg="6">
         <div class="tw-flex">
           <v-col class="question">First Name:</v-col>
           <v-col class="answer">{{customer.nextOfKinFirstName}}</v-col>
         </div>
         <div class="tw-flex">
           <v-col class="question">Last Name:</v-col>
           <v-col class="answer">{{customer.nextOfKinLastName}}</v-col>
         </div>
         <div class="tw-flex">
           <v-col class="question">Relationship:</v-col>
           <v-col class="answer">{{customer.relationship}}</v-col>
         </div>
       </v-col>
       <v-col lg="6">
        <div class="tw-flex">
          <v-col class="question">Email :</v-col>
          <v-col class="answer">{{customer.nextOfKinEmail}}</v-col>
        </div>
        <div class="tw-flex">
          <v-col class="question">Phone :</v-col>
          <v-col class="answer">{{customer.nextOfKinPhoneNumber}}</v-col>
        </div>
       </v-col>

       </div>
    </div>
   </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ViewCustomer",
  data(){
    return {}
  },
  computed:{
    ...mapGetters('customerStore', ['customer'] ),
  },

  methods:{
    routeBack() {
      this.$router.push({
        name: "CustomerDashboard"
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper{

  background-color: #F6F7F9;
  padding-bottom: 96px;
  @media screen and (max-width: 500px) {
    padding-bottom: 8px;
    padding-top: 16px;
  }
}

.content{
  padding: 30px;
  width:650px;
  margin-top:60px ;
  background: var(--offWhite);
  border-radius: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
}
  .customer-details, .next-of-kin-details{
  display: flex;
  margin-top: 36px;
  margin-bottom: 36px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
}

.question, .answer{
  font-family: var(--fontInter);
  font-weight: 300;
  font-size: 14px;
  line-height: 50px;
  color: var(--brown);
}
.answer{
font-weight: 500;
}
.section-title{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--brown);
}
.left {
  font-weight: 500;
  font-size: 19px;
  line-height: 32px;
  color: var(--primaryColor);

  @media (max-width: 576px) {
    font-size: 16px;
  }
}

</style>