<template>
  <div class="new-admin">
    <button class="tw-flex tw-items-center mt-6 overview-btn" @click="routeBack" style="{ color: var(--primaryColor) }">
      <used-icons name="arrow-back" color="var(--primaryColor)" />
      <span class="ml-3">Back</span>
    </button>
    <div class=" display-center-with-flex">
      <div class=" form-group ">
        <!-- validation observer -->
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(addBooking)">

            <div class="admin mt-7" >
              <p class="section-header">Admin Details</p>

              <div class="mb-12 tw-flex general">
                <v-col lg="4" md="4" sm="12" class="">
                  <div class="form-label-div">
                    <p class="label">First Name</p>
                    <p class="label-info">Input the First Name of the Admin</p>
                  </div>
                </v-col>
                <v-col lg="8" md="8" sm="12" class="">
                  <ValidationProvider name="First Name" rules="required" v-slot="{ classes, errors }">
                    <div class="" :class="classes">
                      <v-text-field placeholder="Jane" outlined flat hide-details type="text" required v-model="adminsData.firstName"></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </v-col>
              </div>
              <div class="mb-12 tw-flex general">
                <v-col lg="4" md="4" sm="12" class="">
                  <div class="form-label-div">
                    <p class="label">Last Name</p>
                    <p class="label-info">Input the Last Name of the Admin</p>
                  </div>
                </v-col>
                <v-col lg="8" md="8" sm="12" class="">
                  <ValidationProvider name="Last Name" rules="required" v-slot="{ classes, errors }">
                    <div class="" :class="classes">
                      <v-text-field placeholder="Doe" outlined flat hide-details type="text" required v-model="adminsData.lastName">
                      </v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </v-col>
              </div>
              <div class="mb-12 tw-flex general">
                <v-col lg="4" md="4" sm="12" class="">
                  <div class="form-label-div">
                    <p class="label">Email</p>
                    <p class="label-info">Type the Email Address of the Admin</p>
                  </div>
                </v-col>
                <v-col lg="8" md="8" sm="12" class="">
                  <ValidationProvider name="Admin's Email" rules="required" v-slot="{ classes, errors }">
                    <div class="" :class="classes">
                      <v-text-field placeholder="ryanlila@gmail.com" outlined flat hide-details type="email"
                                    v-model="adminsData.email" required></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </v-col>
              </div>
              <div class="mb-12 tw-flex general">
                <v-col lg="4" md="4" sm="12" class="">
                  <div class="form-label-div">
                    <p class="label">Mobile</p>
                    <p class="label-info">Type the Mobile Number of the Admin</p>
                  </div>
                </v-col>
                <v-col lg="8" md="8" sm="12" class="">
                  <phone-number :phone-number-exist="adminsData.phoneNumber" @getNumber="getNumber" />

                </v-col>
              </div>
            </div>
            <div class="tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div">
              <v-btn text class="zero-btn" @click="routeBack"> Cancel</v-btn>
              <v-btn text type="submit" style="{ background: var(--primaryColor) }" class="second-btn" :loading="doneLoading">
                Save
              </v-btn>
            </div>

          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import usedIcons from "@/components/reusables/UsedIcons";
import phoneNumber from "@/components/reusables/PhoneNumber";
export default {
  name: "NewAdmin",
  components:{usedIcons, phoneNumber},
  computed:{
    ...mapGetters('adminStore', ['adminsData'])
  },
  methods:{
    routeBack() {
      this.$router.push({
        name: "AdminDashboard"
      })
      window.location.reload()
    },
  }

}
</script>

<style scoped lang="scss">
.new-admin{
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0;
  }
}
.form-group {
  padding: 50px;
  border-radius: 10px;
  width: 65%;
  margin-top: 40px;
  background-color: var(--offWhite);
  @media (max-width:1264px) {
    width: 90%;
  }
  @media (max-width:1024px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    padding: 20px;
    margin: 50px 0;
  }
}
.general {
  @media (max-width:960px) {
    flex-direction: column;
  }
}
.form-label-div {
  color: var(--brown);
  text-align: left;
  letter-spacing: 0em;
  margin-right: 30px;

  @media (max-width:960px) {
    margin-bottom: 10px;
  }

  .label {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: left;
    margin-bottom: 8px;

    @media (max-width:960px) {
      margin-bottom: 5px;
    }
  }

  .label-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
  }
}

.action-btn-div {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin-top:70px;
}

.zero-btn,
.second-btn {
  width: 115px;
  height: 40px;
  border-radius: 6px;
  font-family: var(--fontInter);
  text-transform: capitalize;
  color: var(--primaryColor);

  @media (max-width:576px) {
    width: 90px;
    height: 35px;
  }
}


.second-btn {
  background: var(--primaryColor);
  color: var(--white);
  margin-left: 20px;

}


.overview-btn {
  border: none !important;
  font-size: 16px;
  font-family: var(--fontInter);
  font-weight: 500;
  line-height: 19px;
  color: var(--primaryColor);
}
.section-header{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: var(--brown);
  margin-bottom: 50px;
}

</style>