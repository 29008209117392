import dayjs from "dayjs";

export default {
    GET_ALL_TRANSPORT_COMPANIES_TRIPS: (state, payload) => {
        payload.sort(function (a, b) {
            return dayjs(b.takeOffDate+b.takeOffTime).toDate() - dayjs(a.takeOffDate+a.takeOffTime).toDate();
        });
        state.trips = payload;
    },

    SET_CURRENT_TRIP:(state,payload) =>{
        state.currentTrip = payload
    },

    SET_ALL_ONE_WAY_TRIPS: (state,payload) =>{
        state.oneWayTrips = payload
    },

    SET_USER_SELECTED_TRIP: (state,payload) =>{
        state.userSelectedTrip = payload
    },

    SET_ALL_ROUND_TRIPS: (state,payload) =>{
        state.roundTrips = payload
    },

    SET_USER_SELECTED_ROUND_TRIP: (state,payload) =>{
        state.userSelectedRoundTrip = payload
    }

};
