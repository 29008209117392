<template>

      <div  class="right-side-body tw-w-full ">
        <div class="convert tw-flex">
          <v-col md="6" lg="6" cols="12" class="p-12">
            <ValidationProvider name="Name of Business" rules="required" v-slot="{ classes, errors }">
              <label class="label">Name of Business</label>
              <div :class="classes" class="tw-pt-1 mb-1 mt-2">
                <v-text-field solo placeholder="RoadPadi"  type="text" color="#004aad" class="text-capitalize" readonly  hide-details v-model="mobileAgentData.nameOfBusiness"></v-text-field>
                <span> {{errors[0]}} </span>
              </div>
            </ValidationProvider>
          </v-col>
          <v-spacer></v-spacer>
          <v-col lg="6" md="6" cols="12" class="p-12">
            <ValidationProvider name="First Name" rules="required" v-slot="{ classes, errors }">
              <label class="label">First Name</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-text-field placeholder="David" class="text-capitalize" readonly solo hide-details type="url" v-model="mobileAgentData.firstname" required>
                </v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

          </v-col>

        </div>

        <div class="convert tw-flex">
          <v-col md="6" lg="6" cols="12" class="p-12">
            <ValidationProvider name="Name of Business" rules="required" v-slot="{ classes, errors }">
              <label class="label">Last Name</label>
              <div :class="classes" class="tw-pt-1 mb-1 mt-2">
                <v-text-field solo placeholder="Ample"  type="text" color="#004aad" class="text-capitalize" readonly  hide-details v-model="mobileAgentData.lastname"></v-text-field>
                <span> {{errors[0]}} </span>
              </div>
            </ValidationProvider>
          </v-col>
          <v-spacer></v-spacer>

          <v-col lg="6" md="6" cols="12" class="p-12">
              <label class="label">Phone Number</label>
            <div class="tw-pt-1 mb-1 mt-2">
              <phone-number :phone-number-exist="mobileAgentData.phoneNumber" :readonly="true" />
            </div>
          </v-col>

        </div>
        <div class="convert tw-flex">
          <v-col md="6" lg="6" cols="12" class="p-12">
            <ValidationProvider name="Email" rules="required" v-slot="{ classes, errors }">
              <label class="label">Email</label>
              <div :class="classes" class="tw-pt-1 mb-1 mt-2">
                <v-text-field solo placeholder="example@roadpadi.com"  type="email" color="#004aad"
                              class="text-capitalize" readonly  hide-details v-model="mobileAgentData.email">

                </v-text-field>
                <span> {{errors[0]}} </span>
              </div>
            </ValidationProvider>
          </v-col>
          <v-spacer></v-spacer>
          <v-col lg="6" md="6" cols="12" class="p-12">
            <ValidationProvider name="Unique Identifier" rules="required" v-slot="{ classes, errors }">
              <label class="label">Unique Identifier</label>
              <div class="tw-pt-1 mb-1 mt-2" :class="classes">
                <v-text-field placeholder="Unique" class="text-capitalize" readonly solo hide-details type="text"
                              v-model="mobileAgentData.uniqueIdentifier" required>
                </v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

          </v-col>

        </div>


<!--        :disabled="!formIsValid" @click.prevent="saveData"-->
<!--        <div class="tw-flex tw-justify-end ">-->
<!--          <button class="reset-btn btn-div-text mr-3" >-->
<!--            <span>Reset</span>-->
<!--          </button>-->
<!--          <button class="btn-div-text update-btn" >-->
<!--            <span>Update</span>-->
<!--          </button>-->
<!--        </div>-->
      </div>

</template>

<script>

import {mapGetters} from "vuex";
import PhoneNumber from "@/components/reusables/PhoneNumber";

export default {
  name: "EditOnboardingBrand",
  components: {
    PhoneNumber
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters('mobileAgentData',['mobileAgentData']),
  },
  methods: {

  },
  mounted() {

  },
  async created() {

  }
}
</script>

<style scoped lang="scss">

button:disabled,
button[disabled] {
  opacity: 0.7;
}

.right-side-body>.img-logo {
  @media screen and (min-width: 1024px) {
    display: none;
  }
}


.banner-img{
  height: 100px;
  border-radius: 20px;
  object-fit: cover;
  width: 100%;
}
.convert{
@media (max-width: 900px) {
  flex-direction: column;
}
  @media (max-width:1024px) {
    padding-left: 0 !important;
  }
}

.banner-input {
  display: flex;
  position: relative;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: rgba(100, 100, 100, 0.06);
  border-radius: 20px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}

.upload-div {
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  @media (max-width:1264px) {
    margin-left: 0;
  }
}


 .upload-div-text {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--brown);
  margin-bottom: 20px;
}

.color-picker {
  height: 84px;
  width: 84px;
  padding: 20px 05px;
  // background: blue;
  border-radius: 100%;
  cursor: pointer;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 84px;
  width: 84px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}

.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.label {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}

.btn-div-text {
  border-radius: 10px;
  padding: 10px 30px;
  width: 161px;
  justify-content: center;
  margin-top: 20px;
  display: flex;
  align-items: center;
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.reset-btn{
  background-color: var(--white);
  color: var(--primaryColor);
  border: 1.5px solid var(--primaryColor);
}
.update-btn{
  background: var(--primaryColor);
  color: var(--white);
}

.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9,
.col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2,
.col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9,
.col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1,
.col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7,
.col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1{
  padding: 12px !important;
}
</style>