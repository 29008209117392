import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const state = {
    trips : [],
    currentTrip:{},
    oneWayTrips:[],
    userSelectedTrip:{},

    roundTrips:{},
    userSelectedRoundTrip:{},

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
