<template>
  <div class="tw-w-full tw-items-center tw-flex tw-justify-center lg:tw-pt-20">
    <div class="dashboard">
      <v-tabs grow background-color="#fdfffc"  v-model="tabs" slider-color="#4f4f4f"  >
        <v-tab>
          <v-icon >mdi-account-outline</v-icon>  &nbsp;
         Profile</v-tab>
        <v-tab>
          <v-icon>mdi-lock-reset</v-icon>  &nbsp;
          Login & Security</v-tab>
<!--        <v-tab>-->
<!--          <v-icon>mdi-bell-outline</v-icon>  &nbsp;-->

<!--          Notifications</v-tab>-->
      </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item >
    <profile-tab/>
      </v-tab-item>
      <v-tab-item>
        <login-and-security/>
      </v-tab-item><v-tab-item>
      <login-and-security/>
    </v-tab-item>
    </v-tabs-items>
    </div>
  </div>
</template>

<script>
import LoginAndSecurity from "@/views/settings/LoginAndSecurity";
import ProfileTab from "@/views/settings/ProfileTab";
export default {
  name: "SettingDashboard",
  components: {ProfileTab, LoginAndSecurity},
  data(){
    return{
      tabs: null,
    }
  },
  methods:{
    async updatePassword() {
      if (this.passwordData.newPassword === this.passwordData.confirmPassword) {
        this.ispasswordUpdateLoading = true;
        this.passwordData.email = this.companyData.companyEmail;
        await updatePassword(this.passwordData)
            .then((res) => {
              console.log(res.data);
              this.passwordData = {};
              this.ispasswordUpdateLoading = false;
              window.location.reload()
            })
            .catch((err) => {
              this.ispasswordUpdateLoading = false;
              this.$displaySnackbar({
                message: err.toString(),
                success: false,
              });
            });
      } else {
        this.$displaySnackbar({
          message: "New and Confirm Password do not match",
          success: false,
        });
      }
    },

  }
}
</script>

<style scoped lang="scss">
.dashboard{
  background-color: var(--offWhite);
  width: 1000px;
  min-height: 80vh;
  padding: 50px;
  @media(max-width:576px) {
    background-color: transparent;
    width: 100%;
    padding: 0;
  }
}
.v-tab {
  font-family:var(--fontInter);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 19px;
  color: var(--brown);
  text-transform: none;
}
:deep(.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active)) {
  color: rgba(79, 79, 79, 0.42);
}

:deep(.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev ){
  display: none !important;
}

</style>