

export default {
    getBookings({ commit }, payload) {
        commit("GET_BOOKINGS", payload);
    },

    setCurrentBooking({commit},payload){
        commit("SET_CURRENT_BOOKING",payload)
    },
    async addSeat({commit}, payload) {
                commit("ADD_SEAT", payload)
    },
    removeSeat({commit},payload){
        commit("REMOVE_SEAT",payload)
    },

    setOneWayBookingMinutesTimer({commit},payload){
        commit('SET_ONE_WAY_BOOKING_MINUTES_TIMER',payload)
    },

    setOneWayBookingShowTimer({commit},payload){
        commit('SET_ONE_WAY_BOOKING_SHOW_TIMER',payload)
    },

    getTotalAmount({commit},value){
        commit("GET_TOTAL_AMOUNT",value)
    },
    getOneWayTripAndTravellerDetails({commit},details){
        commit("GET_ONE_WAY_TRIP_AND_TRAVELLER_DETAILS",details)
    },

    clearStore({commit}){
        commit("CLEAR_STORE")
    }

};