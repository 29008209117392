import {getAllTripsOfTransportCompaniesSubscribedTo} from "@/services/api/APIService"

export default {
   async getAllTransportCompaniesTrips({ commit }, corporateAgentId) {

       return await getAllTripsOfTransportCompaniesSubscribedTo(corporateAgentId).then(res => {

               commit("GET_ALL_TRANSPORT_COMPANIES_TRIPS", res.data);
               return Promise.resolve()
           },
           error => {
               return Promise.reject(error)
           })
    },

    setCurrentTrip({commit}, trip){
       commit("SET_CURRENT_TRIP",trip)
    },

    setAllOneWayTrips({commit}, trips){
       commit("SET_ALL_ONE_WAY_TRIPS",trips)
    },

    setUserSelectedTrip({commit}, trip){
       commit("SET_USER_SELECTED_TRIP",trip)
        sessionStorage.setItem("userSelectedTrip", JSON.stringify(trip))
    },

    setAllRoundTrips({commit},roundTrips){
       commit('SET_ALL_ROUND_TRIPS',roundTrips)
        if (roundTrips){
            sessionStorage.setItem("queryRoundTrips", JSON.stringify(roundTrips))
        }

    },
    setUserSelectedRoundTrip({commit},roundTrip){
       commit('SET_USER_SELECTED_ROUND_TRIP',roundTrip)
        if (roundTrip){
            sessionStorage.setItem("userSelectedRoundTrip", JSON.stringify(roundTrip))
        }

    }


};