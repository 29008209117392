<template>
  <div class="tw-w-full">
    <loa-der :loading="loading" v-if="loading"/>
  <div class="company" v-else>
    <v-tabs background-color="transparent" v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#all">All</v-tab>
      <v-tab href="#subscribed">Subscribed</v-tab>
      <v-tab href="#unsubscribed">Unsubscribed</v-tab>
     </v-tabs>
    <div class="tw-w-full tw-flex tw-items-center">
      <div class="tw-flex tw-flex-wrap tw-gap-10 lg:tw-gap-5 mt-4">
      <div v-for="(company, index) in filteredCompanies" :key="index" class="single-card mb-2">
        <v-card flat color="#f1f1f1" >
          <div class="tw-w-full tw-flex tw-justify-end ">
            <customized-switch :is-subscribed="company.subscribed" :company-id="company.id"  @getCompanyId="getCompanyId"/>
          </div>
          <div>
            <img :src="company.companyLogo" alt="company" style="object-fit: cover" class="companyLogo" />
            <span class="text-center text-uppercase"> {{ company.companyName }}</span>
          </div>
        </v-card>
      </div>
    </div>
    </div>

  </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import CustomizedSwitch from "@/components/reusables/CustomizedSwitch";
import {
  getAllTransportCompanies,
  subscribeToTransportCompanies,
  unsubscribeToTransportCompanies
} from "@/services/api/APIService";
import LoaDer from "@/components/reusables/Loader";

export default {
  name:"CompanyDashboard",
  components: { LoaDer, CustomizedSwitch},
  props: {
    selected: Number,
  },
  data() {
    return {
      subscribed: true,
      tab:null,
      filteredCompanies: [],
      loading : false,
      doneLoading : false
    };
  },
  computed:{
...mapGetters("companyStore", ['transportCompanies']),
    ...mapGetters("mobileAgentData", ['mobileAgentData'])
  },
  methods: {
    viewCompanyInfo(id){
      this.$router.push({name : "SelectedCompany", query: {id: id }})
    },
    getCompanyId(id){
      this.transportCompanies.forEach(company =>{
        if(company.id === id){
          this.$store.dispatch("companyStore/toggleSubscribe",id)
          this.handleSubscribed()
        }
      })
    },
   async getAllTransportCompanies(){
      this.loading = true
     let companies = []
     await getAllTransportCompanies(this.mobileAgentData.uniqueIdentifier).then(res =>{
       companies = res.data
       companies.forEach(company => company.subscribed = false)
       this.mobileAgentData.transportCompaniesSubscribedTo.forEach(subscribeCompany => {
         companies.forEach(company => {
           if (subscribeCompany.id === company.id){
             company.subscribed = true
           }
         })
       })
       this.$store.dispatch('companyStore/setTransportCompanies',companies)
       this.loading = false
      }).catch(err =>{
        console.log(err)
       this.loading = false
     })
    },
    async handleSubscribed(){
      this.doneLoading = true
     let subscribedCompanies = []
      let unsubscribedCompanies = []
      this.transportCompanies.forEach(company =>{
        if (company.subscribed){
          subscribedCompanies.push(company.id)
        }
        else {
          unsubscribedCompanies.push(company.id)
        }
      })
      let data = {}
      data.transportCompanyIds = subscribedCompanies
      data.mobileAgentId = this.mobileAgentData.id
     await subscribeToTransportCompanies(data).then(async () => {
       data.transportCompanyIds = unsubscribedCompanies
       await unsubscribeToTransportCompanies(data).then(() => {
         this.doneLoading = false
         this.$displaySnackbar({
           message: "Update completed!!",
           success: true,
         });
       }).catch(err =>{
         console.log(err)
         this.doneLoading = false
       })
     }).catch(err =>{
       console.log(err)
       this.doneLoading = false
     })
    }
  },
    watch: {
      tab: {
        handler: function (val) {
          if (val === "all") {
            this.filteredCompanies = this.transportCompanies;
          }
          else if(val === "subscribed"){
            this.filteredCompanies = this.transportCompanies.filter(company => company.subscribed === true);
          }
        else {
            this.filteredCompanies = this.transportCompanies.filter(company => company.subscribed === false);
          }
        },
        immediate: true,
      },
    },

 async created() {
   await this.getAllTransportCompanies()
  }

};
</script>

<style lang="scss" scoped>
.v-tab {
  text-transform: none !important;
}
.text-center {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.single-card {
  cursor: pointer;
}
.single-card:hover {
  box-shadow: 0 0 75px -37px rgba(31, 29, 29, 1);
}
.company .v-card {
  display: flex !important;
  width: 150px;
  height: 150px;
  padding: 5px;
  flex-wrap: wrap;
  flex-direction: column !important;
}
.companyLogo {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  display: flex;
  margin: auto ;
}
.company-select-icon {
  font-size: 18px;
  right: 15px;
  position: absolute;
}
:deep(.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev ){
  display: none !important;
}
.v-input--selection-controls {
  margin-top: 0 !important;
}

:deep(a.v-tab){
  text-transform: none !important;
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--primaryColor) ;
  @media screen and (max-width: 600px) {
    line-height: 20px;
    font-size: 14px;
  }
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  font-family: var(--fontInter);
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px !important;
  color: #BDBDBD;
  @media screen and (max-width: 600px) {
    line-height: 20px;
    font-size: 14px;
  }
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0;
}
.switch-wrapper .v-input--switch--inset .v-input--switch__track {
  height: 50px;
  width: 45px;
}

.switch-wrapper .v-input--switch__thumb {
  height: 15px;
  width: 15px;
  top: calc(50% - 12px);
}

.switch-wrapper .v-input--selection-controls__ripple {
  height: 24px;
  width: 24px;
}

</style>
