import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import "@mdi/font/css/materialdesignicons.css";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import "./assets/css/tailwind.css";
import "./assets/css/global.scss";
import VueApexCharts from 'vue-apexcharts'
import VueFeather from "vue-feather";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import {
  ValidationProvider,
      ValidationObserver,
      extend,
      localize,
      configure
} from 'vee-validate';
import displaySnackbar from "./services/utility/snackbar";
import en from "vee-validate/dist/locale/en.json";
import * as rules from 'vee-validate/dist/rules';

import {
  required
} from "vee-validate/dist/rules";

import './registerServiceWorker'
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.config.productionTip = false
extend("required",
    {
      ...required,
      message: "The {_field_} field is required",
});
Vue.prototype.$displaySnackbar = displaySnackbar;
localize("en", en);
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});

dayjs.extend(LocalizedFormat);
dayjs().format("L LT");
Vue.use(VueApexCharts);
Vue.component("vue-feather", VueFeather);
// eslint-disable-next-line vue/multi-word-components-names
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
