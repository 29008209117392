<template>
  <v-app>
    <snack-bar
        :success="success"
        :show-snack-bar="showSnackBar"
        :snack-bar-message="snackBarMessage"
    />
<v-main style="padding-top: 0">
  <router-view></router-view>
</v-main>
  </v-app>
</template>

<style lang="scss">

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
> .v-input__control
> .v-input__slot {
  box-shadow: none !important;
  border: 1px solid #bdbdbd !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  min-height: 57px !important;
  filter: drop-shadow(0px 4px 20px rgba(63, 51, 86, 0.09)) !important;

  @media screen and (max-width: 1366px) {
    min-height: 47px !important;
  }
}
.v-text-field input {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #4f4f4f !important;
  padding: 16px 16px !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
> .v-input__control
> .v-input__slot:hover {
  border: 1px solid initial !important;
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #fefefe !important;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: none !important;

  &:before {
    display: none;
  }
}
</style>
<script>
import SnackBar from "@/components/reusables/SnackBar";
import {mapGetters} from "vuex";
export default {
  name: "App",
  components: { SnackBar },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters("snackBar", ["showSnackBar", "success", "snackBarMessage"]),
  },
};
</script>