<template>
  <div class="tw-w-full">
    <v-dialog :width="width" v-model="dialog" persistent>

      <!--   Admin Restrict Modal-->
      <v-col v-if="section === 'restrict'">
        <div class="admin-modal">
          <div>
            <h1 class="modal-title mb-5">{{ header }}</h1>
            <p class="modal-subtext">{{ description }}</p>
            <div class="dialog-btn mt-7">
              <v-btn dark color="#004aad" depressed class="btn-no mr-4 btn-generic-style" @click="$emit('closeModal')">Cancel</v-btn>
              <v-btn depressed class="restrict-btn btn-generic-style" @click="$emit('action', userName)">Restrict</v-btn>
            </div>
          </div>
        </div>
      </v-col>
     <!--  Resolve Issue Modal-->
      <v-col v-if="section === 'view-issue'" class="issue-modal ">
        <h1 class="complain">Complaint Details</h1>
        <div class="pending-issue py-8">
          <p class="user-id">Issue <span class="issue-id">{{id}}</span> </p>
          <v-chip color="#16BB8B" text-color="var(--white)" v-if="complaints.status === 'resolved'">{{ complaints.status }}</v-chip>
          <v-chip color="#BA68C8" text-color="var(--white)" v-if="complaints.status === 'pending'">{{ complaints.status }}</v-chip>
          <v-chip color="#AFA259" text-color="var(--white)" v-if="complaints.status === 'forwarded'">{{ complaints.status }}</v-chip>
        </div>
        <div class="chatbox">
          <div class="chat">
          <h1 class="user-name ">{{ complaints.customerName }}</h1>
          <p class="user-problems mt-5">
            Your error seems to indicate that you're calling the method in a Promise. Is this the case? If so, this may not be correctly binding and you may need to explicitly bind the value. Alternatively, you can store this inside a variable accessible via closure and then use that variable instead.
          </p>
          <div class="hours-ago">
            <span class="time mb-8">3 hours ago</span>
          </div>
            <h1 class="user-name ">{{ complaints.customerName }}</h1>
            <p class="user-problems mt-5">
              Your error seems to indicate that you're calling the method in a Promise. Is this the case? If so, this may not be correctly binding and you may need to explicitly bind the value. Alternatively, you can store this inside a variable accessible via closure and then use that variable instead.
            </p>
            <div class="hours-ago">
              <span class="time mb-8">3 hours ago</span>
            </div>          <h1 class="user-name ">{{ complaints.customerName }}</h1>
            <p class="user-problems mt-5">
              Your error seems to indicate that you're calling the method in a Promise. Is this the case? If so, this may not be correctly binding and you may need to explicitly bind the value. Alternatively, you can store this inside a variable accessible via closure and then use that variable instead.
            </p>
            <div class="hours-ago">
              <span class="time mb-8">3 hours ago</span>
            </div>          <h1 class="user-name ">{{ complaints.customerName }}</h1>
            <p class="user-problems mt-5">
              Your error seems to indicate that you're calling the method in a Promise. Is this the case? If so, this may not be correctly binding and you may need to explicitly bind the value. Alternatively, you can store this inside a variable accessible via closure and then use that variable instead.
            </p>
            <div class="hours-ago">
              <span class="time mb-8">3 hours ago</span>
            </div>          <h1 class="user-name ">{{ complaints.customerName }}</h1>
            <p class="user-problems mt-5">
              Your error seems to indicate that you're calling the method in a Promise. Is this the case? If so, this may not be correctly binding and you may need to explicitly bind the value. Alternatively, you can store this inside a variable accessible via closure and then use that variable instead.
            </p>
            <div class="hours-ago">
              <span class="time mb-8">3 hours ago</span>
            </div>

          </div>
        </div>
        <div class="mt-8">
          <label class="reply pb-3">Reply</label>
          <v-textarea v-model="issueData.textInput" auto-grow hide-details outlined name="input-7-1"></v-textarea>
          <div  class="reply-btn">
            <div class="btn-cancel">
              <v-btn depressed class="cancel py-5 px-8" @click="$emit('closeModal')">Cancel</v-btn>
            </div>
            <div class="btn-reply">
              <v-btn dark depressed color="#004aad" class="btn-reply py-5 px-8" @click="replyUserComplan">Reply</v-btn>
            </div>
          </div>
        </div>
      </v-col>
      <!--   Admin Restrict Modal-->
      <v-col v-if="section === 'forward-issue'">
        <div class="admin-modal">
          <div>
            <h1 class="modal-title mb-5">{{ header }}</h1>
            <p class="modal-subtext">{{ description }}</p>
            <div class="dialog-btn mt-7">
              <v-btn dark color="#004aad" depressed class="btn-no mr-4 btn-generic-style" @click="$emit('closeModal')">No</v-btn>
              <v-btn depressed class="restrict-btn btn-generic-style" @click="$emit('action')">Yes</v-btn>

            </div>
          </div>
        </div>
      </v-col>

      <v-col v-if="section === 'wallet-deposit'">
       <div class="admin-modal">
         <h1 class="modal-title mb-5">{{ header }}</h1>

         <v-text-field  solo hide-details placeholder="20000" type="number" v-model="depositAmount">

         </v-text-field>
         <h6 style="color: red">* <span class="modal-subtext"> Your deposit would reflect within 24hrs. Thanks for your patience.</span></h6>
         <div class="dialog-btn mt-7 tw-justify-center tw-w-full tw-flex lg:tw-justify-start">
           <v-btn dark :color="color" depressed class="btn-no mr-4 btn-generic-style" @click="$emit('closeModal')">Cancel</v-btn>
           <v-btn :style="{color: color,
                backgroundColor: 'var(--white)',
                border: '1px solid'+color}" depressed class="btn-generic-style" @click="$emit('action',depositAmount)">Deposit</v-btn>

         </div>
       </div>
      </v-col>

      <v-col v-if="section === 'print-receipt-modal'">
        <div class="admin-modal">
          <h1 class="modal-title mb-5">Booking was successful.</h1>
          <h6 class="modal-subtext"> Booking confirmation email have been sent to customer email address.</h6>

          <div class="dialog-btn mt-7 tw-justify-center tw-w-full tw-flex lg:tw-justify-start">
            <v-btn dark :color="color" depressed class="btn-no mr-4 btn-generic-style" @click="$emit('closeModal')">Close</v-btn>
            <v-btn :style="{color: color,
                backgroundColor: 'var(--white)',
                border: '1px solid'+color}" depressed class="btn-generic-style" @click="$emit('action')">Print Ticket</v-btn>

          </div>
        </div>
      </v-col>

    </v-dialog>
  </div>
</template>

<script>

  import {mapGetters} from "vuex";
export default {
  name: "AllModals",
  components: {
  },
  props: {
    header: {
      type: [String],
      default: "Restrict User",
    },
    description: [String],
    dialog: {
      type: [Boolean],
      default: true,
    },
    width: {
      type: [String],
      default: "284px",
    },
  
    section: {
      type: [String],
      default: "restrict",
    },
    id: {
      type: [String]
    },
    color:{
      type: [String],
      default : "#004AAD"
    }
  },
  data() {
    return {
      issueData: {
        textInput: ''
      },
      depositAmount : null
    }
      },
  methods:{
    replyUserComplan(issueData){
     console.log(issueData)
   }
  },
  computed:{
    ...mapGetters("issueStore", ['complaints'])
  }
};
</script>

<style lang="scss" scoped>
.admin-modal {
  margin: auto;
  background: var(--white);
  border-radius: 10px;
  padding: 30px;
}
.modal-title {
  font-family: var(--fontInter);
  text-align: center;
  color: var(--brown);
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
}
.modal-subtext {
  font-family: var(--fontInter);
  text-align: center;
  color: var(--brown);
  font-size: 13px;
  font-weight: 300;
  line-height: 24px;
}
.btn-generic-style {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  font-family: var(--fontInter);
font-size: 14px;
}
.restrict-btn {
  color: var(--primaryColor);
  background-color: var(--white);
  border: 1px solid var(--primaryColor);
 }

.user-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
}
.user-problems {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  color: #4f4f4f;
}
.time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #a3a2a2;
}
.reply {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.complain {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1d262d;
}
.user-id {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: rgba(0, 17, 39, 0.73);
}
.issue-modal {
  height: 700px;
  padding: 50px !important;
  background: #fdfffc;
  border: 1px solid rgba(6, 28, 58, 0.06);
  border-radius: 10px;
}
.pending-issue {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatbox {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  height: 250px;
  max-height: 260px;
  overflow: auto;
}
.hours-ago {
  display: flex;
  justify-content: flex-end;
}
.reply-btn {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.btn-cancel {
  background: #fdfffc;
  border-radius: 5px;
  border: 1px solid #004aad;
}

.cancel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #004aad;
}
</style>
