
export default {
    SET_CURRENT_COMPONENT: (state, payload) => {
        state.tab = payload;
    },

    SET_DRAWER:(state,payload) =>{
        state.drawer = payload
    }

};
