// import axios from "axios";
// import authHeader from "@/services/api/auth-header";
import callEndpoint from "@/services/api";
import METHODS from "@/services/api/apiMethods";
import VueJwtDecode from "vue-jwt-decode";

const {
  // GET,
  POST,
  // PUT,
  PATCH,
  // DELETE
} = METHODS;

let AUTH_URL;

if (process.env.NODE_ENV === "development") {
  AUTH_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
} else {
  AUTH_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
}

const authServiceCallEndpoint = ({ method, url, data, needsAuth }) => {
  return callEndpoint({ method, url, data, needsAuth }, AUTH_URL);
};

export const login = async (user) => {
  sessionStorage.removeItem("userToken");
  sessionStorage.removeItem("userData");
  const url = "/api/user/auth/login";
  const { data } = await authServiceCallEndpoint({
    method: POST,
    data: user,
    url,
  });
  getUserData(data.access_token)
};

export const getUserData =(data) =>{
  let decodedToken = VueJwtDecode.decode(data);
  const base64Url = data.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
      atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
  );
  sessionStorage.setItem("userData", JSON.stringify(decodedToken));
  sessionStorage.setItem("userToken", JSON.stringify(data));

  return jsonPayload;
}

export const isTokenValid = (exp)=>{
  if (Math.floor(Date.now() / 1000) >= exp){
    sessionStorage.removeItem('userData')
    sessionStorage.removeItem('userToken')
    window.open(process.env.VUE_APP_MAIN_FRONTEND_URL,"_self")
  }
}

export const logout = () => {
  sessionStorage.removeItem("userToken");
  sessionStorage.removeItem("userData");
  sessionStorage.clear()
  window.open(process.env.VUE_APP_MAIN_FRONTEND_URL,"_self")
};


export const customerSignUp = (data) => {
  const url = "/api/customer/register";
  return authServiceCallEndpoint({method: POST, url, data});
};



export const verifyEmail = (data) => {
  const url = "/auth/accounts/verify-registration/";
  return authServiceCallEndpoint({ method: POST, url, data });
};
export const resetPassword = (data) => {
  const url = "/api/user/password-recovery";
  return authServiceCallEndpoint({ method: POST, url, data });
};
export const resetNewPassword = (data, query) => {
  console.log(data);
  console.log(query);
  const url = `/api/user/reset-new-password/?code=${query}`;
  return authServiceCallEndpoint({ method: POST, url, data });
};

export const resetManagerNewPassword = (data, query) => {
  console.log(data);
  console.log(query);
  const url = `/api/user/verify/?code=${query}`;
  return authServiceCallEndpoint({ method: POST, url, data });
};

export const changePassword = (data)=>{
  const url = `/api/user/change-password/${data.email}`
  return authServiceCallEndpoint({method:PATCH,url,data})
}
