<template>
  <div class="selected-company">
      <div>
      <v-btn class="left cursor-pointer" text @click="routeBack">
        <v-icon color="var(--primaryColor)">mdi-arrow-left</v-icon>
        <span class="buttontext"> &nbsp; All Companies</span>
      </v-btn>
    </div>
      <div class="company-details-card display-center-with-flex">
        <div class="container-wrapp ">
          <div class="tw-flex tw-mb-7">
            <img :src="company.companyLogo" alt="company's-logo" class="company-profile-logo mr-4"/>
            <span class="company-headers mt-5">{{ company.companyName }}</span>
          </div>
          <div class="tw-flex  tw-flex-col sm:tw-flex-row">
            <v-col sm="6" cols="12" class="tw-flex">
              <span class="question">Email:</span>
              <p class="answer ml-6">{{ company.companyEmail }}</p>
            </v-col>

            <v-col sm="6" cols="12" class="tw-flex">
              <span class="question">Phone:</span>
              <p class="answer ml-6">{{ company.phoneNumber }}</p>
            </v-col>
          </div>
          <div class="tw-flex  tw-flex-col sm:tw-flex-row">
            <v-col class="tw-flex">
              <span class="question">Fleet:</span>
              <p class="answer ml-6">{{ company.fleet }}</p>
            </v-col>

            <v-col class="tw-flex">
              <span class="question">Terminals:</span>
              <p class="answer ml-6">{{ company.terminals }}</p>
            </v-col>
          </div>
          <div class="company-requirement mt-15">
            <h2 class="company-headers">Requirements</h2>
            <p class="each-requirements" v-for="(requirement,index) in company.requirements" :key="index">
              <v-icon>mdi-circle-small</v-icon>
              {{requirement.one }}</p>
          </div>
          <div class="company-service mt-10 ">
            <h2 class=" company-headers ">Services</h2>
            <p class="company-agent">
              {{ company.Service }}
            </p>
            <div class="company-checkbox  tw-flex my-3">
              <v-checkbox color="#004aad" v-model="company.booking" hide-details></v-checkbox>
              <span class="mt-1 ">Booking</span>
            </div>
            <div class="company-checkbox tw-flex my-3">
              <v-checkbox  color="#004aad" v-model="company.leasing" hide-details></v-checkbox>
              <span class="mt-1 ">Leasing</span>
            </div>
          </div>
          <div>
            <div class="company-mision mt-15">
              <h2 class="company-headers">Mission Statement</h2>
              <p class="sub-text">{{ company.missionStatement }}</p>
            </div>
            <div class="company-vision mt-15">
              <h2 class="company-headers">Vision Statement</h2>
              <p class="sub-text">
                {{ company.visionStatement }}
              </p>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SelectedCompany",
  props: {},
  data() {
    return {
      company:{}
    };
  },
  computed: {
    ...mapGetters("companyStore", ["transportCompanies"]),
  },
  methods: {
    routeBack() {
      this.$router.push({
        name: "CompanyDashboard",
      });
      window.location.reload();
    },
  },
  created() {
    const uri = window.location.search;
    const params = new URLSearchParams(uri);
    const id = params.get("id");
    console.log(this.transportCompanies, id)
    this.transportCompanies.forEach(company => {
      if (company.id === Number.parseInt(id)){
        this.company = company
      }
    })

  },
};
</script>

<style lang="scss" scoped>

.answer{
  font-weight: 500 !important;
}
.answer, .question {
  font-family: var(--fontInter);
  font-weight: 300;
  font-size: 15px;
  line-height: 40px;
  color: var(--brown);
}

.company-profile-logo {
  width: 50px !important;
  height: 50px !important;
  object-fit: cover;
  border-radius: 50% !important;
}
.company-headers {
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
  color: var(--brown);
}

.company-requirement {
  padding-bottom: 10px;
}
.container-wrapp {
  padding: 40px;
  background: var(--offWhite);
  border-radius: 10px;
  margin: 70px 0;
  width: 650px;
  padding: 30px;
  @media (max-width:768px) {
    width: 100%;
  }
}
:deep(.v-btn) {
  height: fit-content;
  text-transform: none;
  padding: 5px 7px !important;
  letter-spacing: 0;
}
:deep(.v-input--selection-controls){
  margin-top: 0;
  padding-top:0 ;
}
.each-requirements {
  font-family: var(--fontInter);
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: var(--brown);
}
.sub-text{
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  font-family: var(--fontInter);
  color: var(--brown);
  font-style: normal;
}


</style>
