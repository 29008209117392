import Vue from 'vue'
import Vuex from 'vuex';
import dashboardLocationTracker from "@/store/dashboardLocationTracker";
import tripStore from "@/store/tripStore";
import snackBar from "@/store/snackBar";
import customerStore from "./customerStore";
import adminStore from "./adminStore";
import bookingStore from "@/store/bookingStore";
import companyStore from "./companyStore"
import issueStore from "./issueStore"
import mobileAgentData from "@/store/mobileAgentData";
import walletStore from "@/store/walletStore";
Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    dashboardLocationTracker,
    tripStore,
    bookingStore,
    snackBar,
    customerStore, 
    companyStore,
    adminStore,
    issueStore,
    mobileAgentData,
    walletStore
  }
})
