

export default {
    GET_BOOKINGS: (state, payload) => {
        state.bookings = payload
    },
    SET_CURRENT_BOOKING: (state, payload) =>{
        state.booking = payload
    },
    ADD_SEAT: (state, value) => {
        state.oneWaySeats = [...state.oneWaySeats,value]
    },
    REMOVE_SEAT: (state, value) =>{
        state.oneWaySeats = state.oneWaySeats.filter(seat => seat.seatNumber !== value.seatNumber)
    },

    GET_TOTAL_AMOUNT: (state, value) =>{
        let uniqueSeats = new Set()
        state.oneWaySeats.forEach(seat => uniqueSeats.add(JSON.stringify(seat)))
        console.log(uniqueSeats.size)
        state.oneWayTotalAmount =  uniqueSeats.size *value
    },

    GET_ONE_WAY_TRIP_AND_TRAVELLER_DETAILS: (state,details) =>{
        state.oneWayTripAndTravellerDetails = details
    },

    SET_ONE_WAY_BOOKING_MINUTES_TIMER: (state, minutesTimer) =>{
        state.oneWayBookingMinutesTimer = minutesTimer
    },

    SET_ONE_WAY_BOOKING_SHOW_TIMER:(state,showTimer) =>{
        state.oneWayBookingShowTimer = showTimer
    },

    CLEAR_STORE: (state)=>{
        state.oneWaySeats = []
        state.oneWayTripAndTravellerDetails = {}
        state.oneWayTotalAmount = 0
    }
};
