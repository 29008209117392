export default function authHeader() {
    let user = JSON.parse(sessionStorage.getItem('userToken'));

    if (user && user.access_token) {
        return { Authorization: `Bearer ${user.access_token}` };
    }
    else if(user != null){
        return {Authorization: `Bearer ${user}`}
    }
    else {
        return {};
    }

}
