import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";


const state = {
    bookings:[],
    booking:{},
    oneWaySeats:[],
    oneWayTotalAmount: 0,
    oneWayTripAndTravellerDetails:{},
    roundTripDepartureSeats:[],
    roundTripReturnSeats:[],
    oneWayBookingMinutesTimer: 0,
    oneWayBookingShowTimer: false,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
