import {getMobileAgentApplicationUserId} from "@/services/api/APIService";

export default {
    async getAgentData({commit}, userId) {
        return await getMobileAgentApplicationUserId(userId).then(
            res => {
                commit("GET_MOBILE_AGENT_DATA", res.data);
                sessionStorage.setItem("mobileAgentData", JSON.stringify(res.data))
                return Promise.resolve()
            },
            error => {
                return Promise.reject(error)
            }
        )
    },

};