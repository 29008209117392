<template>
  <div class="tw-w-full">
    <loa-der :loading="loading" v-if="loading"/>

  <div class="customer-dashboard" v-else>
    <div class="summary-cards">
      <div class="general">
        <div class="mobile-lines left-line-customer-pink"></div>
        <v-card class="total-trips tw-w-full" flat>
          <v-col cols="3">
            <img src="@/assets/all-customers.svg" alt="Total customer icon" class="mt-2"/>
          </v-col>
          <v-col cols="9" class="ml-2 space">
            <p class="heading my-2">{{noOfCustomers}}</p>
            <p class="subheading"> Total Customers</p>
          </v-col>

        </v-card>
      </div>

    <div class="all-customers mt-10">
          <h2 class="tw-my-5  ">All Customers</h2>
      <v-data-table :headers="headers" :items="customers" mobile-breakpoint="0"
                    :hide-default-footer="customers.length <= 10" class="cursor-pointer" @click:row="handleRowClick">
        <template #header.firstName>
          <span class="column-header">First Name</span>
        </template>
        <template v-slot:[`item.firstName`] = "{item}">
          <span class="table-data ">{{item.firstName}}</span>
        </template>

        <template #header.lastName>
          <span class="column-header">Last Name</span>
        </template>
        <template v-slot: [`item.lastName`]="{item}">
          <span class="table-data ">{{item.lastName}}</span>
        </template>

        <template #header.phoneNumber>
          <span class="column-header"> Phone Number</span>
        </template>
        <template v-slot: [`item.phoneNumber`]="{item}">
          <span class="table-data ">{{item.phoneNumber}}</span>
        </template>

        <template #header.email>
          <span class="column-header">Email</span>
        </template>
        <template v-slot:item.email="{item}">
          <span class="table-data row"> {{item.email}} </span>
        </template>

        <template #header.name>
          <span class="column-header"> Next Of Kin First Name</span>
        </template>
        <template v-slot:item.name="{item}">
          <span class="table-data ">{{item.nextOfKinFirstName}}</span>
        </template>

        <template #header.nokMobile>
          <span class="column-header"> Next Of Kin Phone Number</span>
        </template>
        <template v-slot:item.nokMobile="{item}">
          <span class="table-data ">{{item.nextOfKinPhoneNumber}}</span>
        </template>

      </v-data-table>
    </div>
    </div>
  </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getAllBookings} from "@/services/api/APIService";
import LoaDer from "@/components/reusables/Loader";

export default{
  name:'CustomerDashboard',
  components: {LoaDer},
  data(){
    return {
      headers: [
        {
          text: '',
          value: 'firstName',
          sortable: false
        },
        {
          text: '',
          value: 'lastName',
          align: "start",
          sortable: false
        },
        {
          text: '',
          value: 'phoneNumber',
          align: "start",
          sortable: false
        },
        {
          text: '',
          value: 'email',
          align: "start",
          sortable: false
        },
        {
          text: '',
          value: 'name',
          align: "start",
          sortable: false

        },

        {
          text: '',
          value: 'nokMobile',
          align: "start",
          sortable: false
        },
      ],
      noOfCustomers:"",
      loading: false
    }
    },
  computed:{
    ...mapGetters('customerStore', ['customers']),
    ...mapGetters('mobileAgentData', ['mobileAgentData'])
  },

  methods:{
    handleRowClick(customer) {
      this.$store.dispatch('customerStore/setCurrentSelectedCustomer', customer)
      this.$router.push({
        name: 'ViewCustomer'
      })
    },
    async getAllBookings(){
      this.loading = true
      let customers = new Set();
      let uniqueCustomers = []
      await getAllBookings(this.mobileAgentData.id).then(res =>{
        res.data.forEach(booking =>{
          booking.travellerDetails.forEach(traveler => customers.add(JSON.stringify(traveler)))
        })
        this.loading = false
        customers.forEach(customer => uniqueCustomers.push(JSON.parse(customer)))
        this.$store.dispatch('customerStore/setCurrentCustomers',uniqueCustomers)
        this.noOfCustomers = uniqueCustomers.length
      }).catch(err =>{
        console.log(err)
        this.loading = false
      })
    },

  },

  async created() {
    await this.getAllBookings()
  }

}
</script>

<style lang="scss" scoped>
h2{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--brown);
}
.all-customers{
  padding: 30px;
  background-color: var(--offWhite);
  box-shadow: 0px 4px 40px rgba(79, 79, 79, 0.02);
  border-radius: 10px;
}
.general{
  display: flex;
  width: 250px ;
  height: 100px;
  font-family: var(--fontInter);
  color: var(--brown);
  margin-right: 20px;
  @media (max-width: 1560px) {
    margin: 10px 10px 10px 0;
  }
  @media (max-width:1024px) {
    width: 45%;
  }
  @media (max-width:576px) {
    width: 100%;
  };
}
.general > .v-card{
  padding: 20px;
  background: var(--offWhite);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px !important;
  display: flex;
}

.heading {
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  color: var(--brown);
  font-family: var(--fontInter);
  @media (max-width:768px) {
    font-size: 14px;
  }
}
.space {
  @media (max-width:650px) {
    margin-left: 20px;
  }

  @media (max-width:576px) {
    margin-left: 0;
  }
}

.subheading {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: var(--brown);
  font-family: var(--fontInter);
}

.grouped-cards {
  background-color: var(--white);
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  padding: 40px;

  @media (max-width:820px) {
    padding: 25px;
  }
}

:deep(.v-data-table  .v-data-table__wrapper  table  tbody  tr  td) {
  font-family: 'Inter';
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  color: var(--brown);
}

.cta-text {
  font-family: var(--fontInter);
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  cursor: pointer;
}

.menu-icon {
  font-size: 15px;
}

.status-icon {
  font-size: 13px;
}

.status {
  text-transform: capitalize;
  margin-left: 5px;
  color: var(--white);
}

.mobile {
  @media (max-width:820px) {
    display: none;
  }
}
:deep(.theme--light.v-data-table.v-data-table__wrappertabletheadtrth),
.column-header{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--brown);
  text-align: left;
  white-space: nowrap;
}

.search-bar {
  @media (max-width:820px) {
    width: 100%
  }
}

:deep(.theme--light.v-data-table.v-data-table__wrapper table tr) {
  height: 60px;
}

:deep(.theme--light.v-data-table) {
  background-color: var(--offWhite);
}

:deep(.v-data-table .v-data-table__wrapper table tbody tr td) {
  white-space: nowrap !important;
}
.v-chip{
  width:100px;
  display:flex;
  align-items: center;
  justify-content: center;
}
::v-deep .v-tabs {
  background-color: var(--offWhite) !important;
}
::v-deep a.v-tab{
  text-transform: none !important;
  font-family: var(--fontInter);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #004AAD ;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #BDBDBD;
  @media screen and (max-width: 600px) {
    line-height: 20px;
  }
}

.v-tab {
  min-width: 0 !important;
}

</style>