import { render, staticRenderFns } from "./UsedIcons.vue?vue&type=template&id=5214896b&scoped=true&"
import script from "./UsedIcons.vue?vue&type=script&lang=js&"
export * from "./UsedIcons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5214896b",
  null
  
)

export default component.exports