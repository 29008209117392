<template>
  <div>
    <svg
        v-if="name === 'dashboard'"
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M2.16667 6.50033C1.72464 6.50033 1.30072 6.32473 0.988155 6.01217C0.675595 5.69961 0.5 5.27569 0.5 4.83366V2.33366C0.5 1.89163 0.675595 1.46771
    0.988155 1.15515C1.30072 0.842587 1.72464 0.666992 2.16667 0.666992H5.5C5.94203 0.666992 6.36595 0.842587
    6.67851 1.15515C6.99107 1.46771 7.16667 1.89163 7.16667 2.33366V4.83366C7.16667 5.27569 6.99107 5.69961 6.67851 6.01217C6.36595 6.32473 5.94203 6.50033 5.5 6.50033H2.16667ZM2.16667 4.83366H5.5V2.33366H2.16667V4.83366ZM2.16667 17.3337C1.72464 17.3337 1.30072 17.1581 0.988155 16.8455C0.675595 16.5329 0.5 16.109 0.5 15.667V9.00033C0.5 8.5583 0.675595 8.13438 0.988155 7.82181C1.30072 7.50925 1.72464 7.33366 2.16667 7.33366H5.5C5.94203 7.33366 6.36595 7.50925 6.67851 7.82181C6.99107 8.13438 7.16667 8.5583 7.16667 9.00033V15.667C7.16667 16.109 6.99107 16.5329 6.67851 16.8455C6.36595 17.1581 5.94203 17.3337 5.5 17.3337H2.16667ZM2.16667 15.667H5.5V9.00033H2.16667V15.667ZM8.83333 15.667C8.83333 16.109 9.00893 16.5329 9.32149 16.8455C9.63405 17.1581 10.058 17.3337 10.5 17.3337H13.8333C14.2754 17.3337 14.6993 17.1581 15.0118 16.8455C15.3244 16.5329 15.5 16.109 15.5 15.667V14.0003C15.5 13.5583 15.3244 13.1344 15.0118 12.8218C14.6993 12.5093 14.2754 12.3337 13.8333 12.3337H10.5C10.058 12.3337 9.63405 12.5093 9.32149 12.8218C9.00893 13.1344 8.83333 13.5583 8.83333 14.0003V15.667ZM13.8333 15.667H10.5V14.0003H13.8333V15.667ZM10.5 10.667C10.058 10.667 9.63405 10.4914 9.32149 10.1788C9.00893 9.86628 8.83333 9.44235 8.83333 9.00033V2.33366C8.83333 1.89163 9.00893 1.46771 9.32149 1.15515C9.63405 0.842587 10.058 0.666992 10.5 0.666992H13.8333C14.2754 0.666992 14.6993 0.842587 15.0118 1.15515C15.3244 1.46771 15.5 1.89163 15.5 2.33366V9.00033C15.5 9.44235 15.3244 9.86628 15.0118 10.1788C14.6993 10.4914 14.2754 10.667 13.8333 10.667H10.5ZM10.5 9.00033H13.8333V2.33366H10.5V9.00033Z"
          :fill="color"
      />
    </svg>
    <svg width="18" height="16" v-if="name === 'forward-issue'" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.3359 1.16602L16.5026 5.33268L12.3359 9.49935" stroke="#CABD72" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.5 5.33203H9.83333C5.23083 5.33203 1.5 9.06286 1.5 13.6654V14.4987" stroke="#CABD72" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    <svg width="17" height="17" v-if="name === 'resolve-issue'" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 11.1562C9.96701 11.1562 11.1562 9.96701 11.1562 8.5C11.1562 7.03299 9.96701 5.84375 8.5 5.84375C7.03299 5.84375 5.84375 7.03299 5.84375 8.5C5.84375 9.96701 7.03299 11.1562 8.5 11.1562Z" stroke="#D0228E" stroke-width="1.0625" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.5 15.9375C12.6076 15.9375 15.9375 12.6076 15.9375 8.5C15.9375 4.39238 12.6076 1.0625 8.5 1.0625C4.39238 1.0625 1.0625 4.39238 1.0625 8.5C1.0625 12.6076 4.39238 15.9375 8.5 15.9375Z" stroke="#D0228E" stroke-width="1.0625" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    <svg v-if="name === 'issues'" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7999 13.5829L12.4083 2.98292C12.1498 2.5786 11.7937 2.24585 11.3728 2.01536C10.9519 1.78488 10.4798 1.66406 9.99992 1.66406C9.52005 1.66406 9.04791 1.78488 8.62702 2.01536C8.20613 2.24585 7.85004 2.5786 7.59159 2.98292L1.19992 13.5829C0.974132 13.9593 0.851411 14.3885 0.844097 14.8273C0.836783 15.2662 0.945133 15.6992 1.15825 16.0829C1.40465 16.5148 1.7613 16.8735 2.19176 17.1224C2.62221 17.3713 3.11103 17.5015 3.60825 17.4996H16.3916C16.8855 17.5049 17.3722 17.3797 17.8023 17.1369C18.2325 16.894 18.591 16.5419 18.8416 16.1162C19.061 15.7286 19.1728 15.2893 19.1655 14.8439C19.1581 14.3985 19.0319 13.9632 18.7999 13.5829ZM17.3833 15.2912C17.2831 15.4634 17.1378 15.6049 16.963 15.7004C16.7882 15.7958 16.5906 15.8417 16.3916 15.8329H3.60825C3.40927 15.8417 3.21168 15.7958 3.03689 15.7004C2.86209 15.6049 2.71674 15.4634 2.61659 15.2912C2.54345 15.1646 2.50494 15.0209 2.50494 14.8746C2.50494 14.7283 2.54345 14.5846 2.61659
       14.4579L9.01659 3.84958C9.13159 3.69767 9.28023 3.57447 9.45084 3.48964C9.62144 3.40482 9.80939 3.36067 9.99992 3.36067C10.1905 3.36067 10.3784 3.40482 10.549 3.48964C10.7196 3.57447 10.8683 3.69767 10.9833 3.84958L17.3749 14.4496C17.4509 14.5765 17.4917 14.7214 17.4932 14.8693C17.4946 15.0172 17.4567 15.1628 17.3833 15.2912Z" :fill="color"/>
      <path d="M9.9974 14.1667C10.4576 14.1667 10.8307 13.7936 10.8307 13.3333C10.8307 12.8731 10.4576 12.5 9.9974 12.5C9.53716 12.5 9.16406 12.8731 9.16406 13.3333C9.16406 13.7936 9.53716 14.1667 9.9974 14.1667Z" :fill="color"/>
      <path d="M9.9974 6.66602C9.77638 6.66602 9.56442 6.75381 9.40814 6.91009C9.25186 7.06637 9.16406 7.27834 9.16406 7.49935V10.8327C9.16406 11.0537 9.25186 11.2657 9.40814 11.4219C9.56442 11.5782 9.77638 11.666 9.9974 11.666C10.2184 11.666 10.4304 11.5782 10.5867 11.4219C10.7429 11.2657 10.8307 11.0537 10.8307 10.8327V7.49935C10.8307 7.27834 10.7429 7.06637 10.5867 6.91009C10.4304 6.75381 10.2184 6.66602 9.9974 6.66602Z" :fill="color"/>
    </svg>
    <svg v-if="name === 'admins'" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6615 9.16667C16.6615 7.39856 15.9591 5.70286 14.7088 4.45262C13.4586 3.20238 11.7629 2.5 9.99479 2.5C8.22668 2.5 6.53099 3.20238 5.28075 4.45262C4.0305 5.70286 3.32813 7.39856 3.32812 9.16667" :stroke="color" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.6641 14.9998V15.4165C16.6641 15.8585 16.4885 16.2824 16.1759 16.595C15.8633 16.9075 15.4394 17.0831 14.9974 17.0831H12.0807M1.66406 12.8648V11.3015C1.66412 10.9298 1.7884 10.5688 2.01715 10.2759C2.2459 9.98298 2.566 9.77492 2.92656 9.68479L4.37656 9.32146C4.45024 9.30311 4.52713 9.30178 4.6014 9.31758C4.67567 9.33337 4.74537 9.36588 4.80521 9.41262C4.86504 9.45937 4.91345 9.51912 4.94675 9.58736C4.98005 9.6556 4.99737 9.73053 4.9974 9.80646V14.359C4.99753 14.4351 4.98029 14.5102 4.947 14.5786C4.9137 14.647 4.86523 14.7069 4.80528 14.7538C4.74533 14.8006 4.67547 14.8332 4.60103 14.849C4.52659 14.8647 4.44953 14.8633 4.37573 14.8448L2.92573 14.4823C2.56532 14.392 2.24541 14.1839 2.01682 13.891C1.78823 13.5981 1.66407 13.2372 1.66406 12.8656V12.8648ZM18.3307 12.8648V11.3015C18.3307
      10.9298 18.2064 10.5688 17.9776 10.2759C17.7489 9.98298 17.4288 9.77492 17.0682 9.68479L15.6182 9.32146C15.5445 9.30311 15.4677 9.30178 15.3934 9.31758C15.3191 9.33337 15.2494 9.36588 15.1896 9.41262C15.1297 9.45937 15.0813 9.51912 15.048 9.58736C15.0147 9.6556 14.9974 9.73053 14.9974 9.80646V14.359C14.9973 14.435 15.0145 14.51 15.0478 14.5783C15.081 14.6467 15.1294 14.7065 15.1893 14.7534C15.2491 14.8002 15.3189 14.8328 15.3932 14.8486C15.4675 14.8645 15.5445 14.8632 15.6182 14.8448L17.0682 14.4823C17.4288 14.3922 17.7489 14.1841 17.9776 13.8912C18.2064 13.5983 18.3307 13.2373 18.3307 12.8656V12.8648Z" :stroke="color" stroke-width="1.25"/>
      <path d="M11.25 18.334H8.75C8.41848 18.334 8.10054 18.2023 7.86612 17.9679C7.6317 17.7334 7.5 17.4155 7.5 17.084C7.5 16.7525 7.6317 16.4345 7.86612 16.2001C8.10054 15.9657 8.41848 15.834 8.75 15.834H11.25C11.5815 15.834 11.8995 15.9657 12.1339 16.2001C12.3683 16.4345 12.5 16.7525 12.5 17.084C12.5 17.4155 12.3683 17.7334 12.1339 17.9679C11.8995 18.2023 11.5815 18.334 11.25 18.334Z" :stroke="color" stroke-width="1.25"/>
    </svg>
    <svg
        v-if="name === 'trips'"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M13.8333 10.65V3.83333C13.8333 1.99167 12.3417 0.5 10.5 0.5C8.65833 0.5 7.16667 1.99167 7.16667 3.83333V12.1667C7.16667 13.0833 6.41667 13.8333 5.5 13.8333C4.58333 13.8333 3.83333 13.0833 3.83333 12.1667V5.35C4.8 5 5.5 4.08333 5.5 3C5.5 1.61667 4.38333 0.5 3 0.5C1.61667 0.5 0.5 1.61667 0.5 3C0.5 4.08333 1.2 5 2.16667 5.35V12.1667C2.16667 14.0083 3.65833 15.5 5.5 15.5C7.34167 15.5 8.83333 14.0083 8.83333 12.1667V3.83333C8.83333 2.91667 9.58333 2.16667 10.5 2.16667C11.4167 2.16667 12.1667 2.91667 12.1667 3.83333V10.65C11.6117 10.8466 11.1441 11.2331 10.8464 11.741C10.5487 12.249 10.4401 12.8458 10.5398 13.4261C10.6395 14.0064 10.9411 14.5328 11.3913 14.9122C11.8414 15.2917 12.4112 15.4999 13 15.5C14.3833 15.5 15.5 14.3833 15.5 13C15.5 11.9167 14.8 11 13.8333 10.65Z"
          :fill="color"
      />
    </svg>
    <svg
        v-if="name === 'logout'"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M4.16667 4.16667H9.16667C9.625 4.16667 10 3.79167 10 3.33333C10 2.875 9.625 2.5 9.16667 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H9.16667C9.625 17.5 10 17.125 10 16.6667C10 16.2083 9.625 15.8333 9.16667 15.8333H4.16667V4.16667Z"
          :fill="color"
      />
      <path
          d="M17.2083 9.70834L14.8833 7.38334C14.8254 7.3238 14.751 7.2829 14.6696 7.26589C14.5883 7.24888 14.5037 7.25653 14.4268 7.28785C14.3498 7.31917 14.284 7.37274 14.2376 7.44171C14.1913 7.51068 14.1666 7.59191 14.1667 7.675V9.16667H8.33333C7.875 9.16667 7.5 9.54167 7.5 10C7.5 10.4583 7.875 10.8333 8.33333 10.8333H14.1667V12.325C14.1667 12.7 14.6167 12.8833 14.875 12.6167L17.2 10.2917C17.3667 10.1333 17.3667 9.86667 17.2083 9.70834Z"
          :fill="color"
      />
    </svg>
    <svg
        v-if="name === 'trash'"
        width="10"
        height="13"
        viewBox="0 0 10 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M9.58 4.94L8.91333 6.09333L0.826666 1.42666L1.49333 0.273331L3.52 1.44L4.42667 1.19333L7.31333 2.86L7.56 3.77333L9.58 4.94ZM0 11.6667V3.66666H3.38L8 6.33333V11.6667C8 12.0203 7.85952 12.3594 7.60947 12.6095C7.35943 12.8595 7.02029 13 6.66667 13H1.33333C0.979711 13 0.640573 12.8595 0.390524 12.6095C0.140476 12.3594 0 12.0203 0 11.6667ZM1.33333 11.6667H6.66667V7.13333L2.97333 5H1.33333V11.6667Z"
          :fill="color"
      />
    </svg>
    <svg v-if="name === 'bell'" width="13" style="display: inline" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7907 5.95349C10.7907 4.76927 10.3203 3.63355 9.4829 2.79617C8.64552 1.9588 7.5098 1.48837 6.32558 1.48837C5.14136 1.48837 4.00564 1.9588 3.16827 2.79617C2.3309 3.63355 1.86047 4.76927 1.86047 5.95349V11.907H10.7907V5.95349ZM12.2791 12.4033L12.5767 12.8C12.6182 12.8553 12.6435 12.921 12.6497 12.9898C12.6559 13.0587 12.6428 13.1279 12.6119 13.1897C12.581 13.2515 12.5335 13.3034 12.4747 13.3398C12.4159 13.3761 12.3482 13.3953 12.2791 13.3953H0.372093C0.302991 13.3953 0.235254 13.3761 0.176472 13.3398C0.117691 13.3034 0.0701865 13.2515 0.039283 13.1897C0.00837963 13.1279 -0.00470207 13.0587 0.00150369 12.9898C0.00770945 12.921 0.0329574 12.8553 0.0744187 12.8L0.372093 12.4033V5.95349C0.372093 4.37453 0.999334 2.86023 2.11583 1.74374C3.23233 0.627241 4.74662 0 6.32558 0C7.90455 0 9.41884 0.627241 10.5353 1.74374C11.6518 2.86023 12.2791 4.37453 12.2791 5.95349V12.4033ZM4.46512
      14.1395H8.18605C8.18605 14.633 7.99003 15.1062 7.64113 15.4551C7.29222 15.804 6.81901 16 6.32558 16C5.83216 16 5.35894 15.804 5.01003 15.4551C4.66113 15.1062 4.46512 14.633 4.46512 14.1395Z" :fill="color"/>
    </svg>
    <svg v-if="name === 'search'" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 14L10.9306 10.9252L14 14ZM12.6316 6.81579C12.6316 8.35823 12.0188 9.8375 10.9282 10.9282C9.8375 12.0188 8.35823 12.6316
       6.81579 12.6316C5.27335 12.6316 3.79408 12.0188 2.70341 10.9282C1.61273 9.8375 1 8.35823 1 6.81579C1 5.27335 1.61273 3.79408 2.70341
        2.70341C3.79408 1.61273 5.27335 1 6.81579 1C8.35823 1 9.8375 1.61273 10.9282 2.70341C12.0188 3.79408 12.6316 5.27335 12.6316 6.81579V6.81579Z"
            :stroke="color" stroke-width="1.36842" stroke-linecap="round"/>
    </svg>
    <svg v-if="name === 'wallet'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66406 6.66732H18.3307M1.66406 5.00065C1.66406 4.55862 1.83966 4.1347 2.15222 3.82214C2.46478 3.50958 2.8887
       3.33398 3.33073 3.33398H16.6641C17.1061 3.33398 17.53 3.50958 17.8426 3.82214C18.1551 4.1347 18.3307 4.55862 18.3307 5.00065V15.0007C18.3307 15.4427 18.1551 15.8666 17.8426 16.1792C17.53 16.4917 17.1061 16.6673
       16.6641 16.6673H3.33073C2.8887 16.6673 2.46478 16.4917 2.15222 16.1792C1.83966 15.8666 1.66406 15.4427 1.66406 15.0007V5.00065Z" :stroke="color" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.66406 10H7.4974C7.4974 10.8333 7.9974 12.5 9.9974 12.5C11.9974 12.5 12.4974 10.8333 12.4974 10H18.3307" :stroke="color" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-if="name === 'download'" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.125 0C13.0667 0.391667 16.2083 3.51667 16.5833 7.475C16.6932 8.56304 16.5872 9.66206 16.2716 10.7091C15.956 11.7561 15.4369 12.7306 14.7441 13.5767C14.0513 14.4228 13.1983 15.1239 12.2341 15.6398C11.2699 16.1558 10.2133 16.4764 9.125 16.5833V14.9167C12.1583 14.5583 14.5417 12.15 14.925 9.11667C15.033 8.24765 14.9685 7.36587 14.7353 6.52178C14.5022 5.67769 14.1049 4.88785 13.5662 4.19745C13.0275 3.50705 12.358 2.92963 11.5959 2.49824C10.8338 2.06684 9.99419 1.78992 9.125 1.68333V0ZM7.45833 0.025V1.69167C6.26667 1.85833 5.14167 2.34167 4.20833 3.09167L3.01667 1.85833C4.28059 0.816826 5.82776 0.178217 7.45833 0.025ZM1.84167 3.03333L3.03333 4.225C2.29499 5.16642 1.823 6.28884 1.66667 7.475H0C0.166667 5.84167 0.791667 4.3 1.84167 3.03333ZM0.00833333 9.14167H1.675C1.825 10.325 2.3 11.45 3.03333 12.3917L1.85 13.5833C0.814732 12.3149 0.174394 10.7705 0.00833333 9.14167ZM4.20833 13.6167C5.15 14.35 6.275 14.825 7.45833 14.975V16.6417C5.825 16.4667 4.275 15.8083 3.01667 14.7583L4.20833 13.6167ZM8.29167 12.0583L4.54167 8.30833H7.45833V4.975H9.125V8.30833H12.0417L8.29167 12.0583Z" fill="black" fill-opacity="0.63"/>
    </svg>
    <svg
        v-if="name === 'arrow-back'"
        width="19"
        height="8"
        viewBox="0 0 19 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M0.646446 3.64645C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM19 3.5L1 3.5V4.5L19 4.5V3.5Z"
          :fill="color"
      />
    </svg>
    <svg
        v-if="name === 'edit'"
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M12.8336 15.6667H1.16691C0.9459 15.6667 0.733939 15.7545 0.577658 15.9107C0.421378 16.067 0.333581 16.279 0.333581 16.5C0.333581 16.721 0.421378 16.933 0.577658 17.0893C0.733939 17.2455 0.9459 17.3333 1.16691 17.3333H12.8336C13.0546 17.3333 13.2666 17.2455 13.4228 17.0893C13.5791 16.933 13.6669 16.721 13.6669 16.5C13.6669 16.279 13.5791 16.067 13.4228 15.9107C13.2666 15.7545 13.0546 15.6667 12.8336 15.6667ZM1.16691 14H1.24191L4.71691 13.6833C5.09758 13.6454 5.45361 13.4777 5.72525 13.2083L13.2252 5.70833C13.5163 5.4008 13.6737 4.99042 13.6627 4.56711C13.6518 4.1438 13.4735 3.74209 13.1669 3.45L10.8836 1.16667C10.5856 0.886745 10.1951 0.726131 9.78636 0.715375C9.37764 0.70462 8.97923 0.844473 8.66691 1.10833L1.16691 8.60833C0.897552 8.87997 0.729834 9.236 0.691914 9.61666L0.333581 13.0917C0.322355 13.2137 0.338193 13.3368 0.379965 13.452C0.421737 13.5672 0.488416 13.6718 0.575247 13.7583C0.653114 13.8356 0.745461 13.8967 0.846992 13.9381C0.948523 13.9796 1.05724 14.0006 1.16691 14ZM9.72525 2.33333L12.0002 4.60833L10.3336 6.23333L8.10025 4L9.72525 2.33333ZM2.30858 9.75833L7.00025 5.1L9.25025 7.35L4.58358 12.0167L2.08358 12.25L2.30858 9.75833Z"
          :fill="color"
          fill-opacity="0.76"
      />
    </svg>
    <svg width="16" height="16" v-if="name === 'cancel'" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.75737 3.75737C3.20022 4.31453 2.75826 4.97596 2.45673 5.70392C2.1552 6.43188 2 7.2121 2 8.00004C2 8.78797 2.1552 9.56819 2.45673 10.2962C2.75826 11.0241 3.20021 11.6855 3.75737 12.2427C4.31452 12.7999 4.97596 13.2418 5.70392 13.5433C6.43188 13.8449 7.2121 14.0001 8.00004 14.0001C8.78797 14.0001 9.56819 13.8449 10.2962 13.5433C11.0241 13.2418 11.6855 12.7999 12.2427 12.2427M3.75737 3.75737C4.31453 3.20022 4.97596 2.75826 5.70392 2.45673C6.43188 2.1552 7.2121 2 8.00004 2C8.78797 2 9.56819 2.1552 10.2962 2.45673C11.0241 2.75826 11.6855 3.20021 12.2427 3.75737C12.7999 4.31452 13.2418 4.97596 13.5433 5.70392C13.8449 6.43188 14.0001 7.2121 14.0001 8.00004C14.0001 8.78797 13.8449 9.56819 13.5433 10.2962C13.2418 11.0241 12.7999 11.6855 12.2427 12.2427M3.75737 3.75737L8.00004 8.00004L12.2427 12.2427" stroke="#D3766A" stroke-width="1.41667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg
        v-if="name === 'confirm-delete'"
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <circle
          cx="30"
          cy="30"
          r="28.5"
          fill="#FDFFFC"
          :stroke="color"
          stroke-width="3"
      />
      <path
          d="M25.8262 27.4491V37.6532C25.8262 37.9915 25.9728 38.3159 26.2337 38.5551C26.4947 38.7943 26.8486 38.9287 27.2176 38.9287C27.5867 38.9287 27.9406 38.7943 28.2016 38.5551C28.4625 38.3159 28.6091 37.9915 28.6091 37.6532V27.4491C28.6091 27.1108 28.4625 26.7864 28.2016 26.5472C27.9406 26.308 27.5867 26.1736 27.2176 26.1736C26.8486 26.1736 26.4947 26.308 26.2337 26.5472C25.9728 26.7864 25.8262 27.1108 25.8262 27.4491Z"
          :fill="color"
      />
      <path
          d="M32.784 26.1736C33.1531 26.1736 33.507 26.308 33.768 26.5472C34.0289 26.7864 34.1755 27.1108 34.1755 27.4491V37.6532C34.1755 37.9915 34.0289 38.3159 33.768 38.5551C33.507 38.7943 33.1531 38.9287 32.784 38.9287C32.415 38.9287 32.0611 38.7943 31.8001 38.5551C31.5392 38.3159 31.3926 37.9915 31.3926 37.6532V27.4491C31.3926 27.1108 31.5392 26.7864 31.8001 26.5472C32.0611 26.308 32.415 26.1736 32.784 26.1736Z"
          :fill="color"
      />
      <path
          d="M35.5673 19.7959H43.9161C44.2851 19.7959 44.6391 19.9303 44.9 20.1695C45.161 20.4087 45.3076 20.7331 45.3076 21.0714C45.3076 21.4097 45.161 21.7341 44.9 21.9733C44.6391 22.2125 44.2851 22.3469 43.9161 22.3469H42.3771L40.2844 39.6326C40.0953 41.1927 39.2853 42.634 38.0093 43.6808C36.7334 44.7276 35.0811 45.3063 33.3688 45.3061H26.6341C24.9217 45.3063 23.2695 44.7276 21.9935 43.6808C20.7176 42.634 19.9075 41.1927 19.7185 39.6326L17.623 22.3469H16.0868C15.7177 22.3469 15.3638 22.2125 15.1029 21.9733C14.8419 21.7341 14.6953 21.4097 14.6953 21.0714C14.6953 20.7331 14.8419 20.4087 15.1029 20.1695C15.3638 19.9303 15.7177 19.7959 16.0868 19.7959H24.4356C24.4356 18.4427 25.022 17.145 26.0658 16.1882C27.1096 15.2314 28.5253 14.6938 30.0014 14.6938C31.4776 14.6938 32.8933 15.2314 33.9371 16.1882C34.9809 17.145 35.5673 18.4427 35.5673 19.7959ZM30.0014 17.2449C29.2634 17.2449 28.5555 17.5136 28.0336 17.992C27.5117 18.4705 27.2185 19.1193 27.2185 19.7959H32.7844C32.7844 19.1193 32.4912 18.4705 31.9693 17.992C31.4474 17.5136 30.7395 17.2449 30.0014 17.2449ZM20.4254 22.3469L22.4847 39.352C22.5984 40.2879 23.0845 41.1524 23.8501 41.7803C24.6156 42.4081 25.6069 42.7552 26.6341 42.7551H33.3688C34.3955 42.7546 35.3861 42.4072 36.151 41.7794C36.916 41.1516 37.4017 40.2874 37.5153 39.352L39.5803 22.3469H20.4282H20.4254Z"
          :fill="color"
      />
    </svg>
    <svg
        v-if="name === 'arrowDown'"
        width="12"
        height="19"
        viewBox="0 0 12 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M5.46967 18.5303C5.76256 18.8232 6.23744 18.8232 6.53033 18.5303L11.3033 13.7574C11.5962 13.4645 11.5962 12.9896
       11.3033 12.6967C11.0104 12.4038 10.5355 12.4038 10.2426 12.6967L6 16.9393L1.75736 12.6967C1.46447 12.4038 0.989593 12.4038 0.6967
       12.6967C0.403806 12.9896 0.403806 13.4645 0.6967 13.7574L5.46967 18.5303ZM5.25 3.27836e-08L5.25 18L6.75 18L6.75 -3.27836e-08L5.25 3.27836e-08Z"
          :fill="color"
      />
    </svg>
    <svg
        v-if="name === 'arrowUp'"
        width="12"
        height="19"
        viewBox="0 0 12 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M5.46967 0.469669C5.76256 0.176777 6.23744 0.176777 6.53033 0.469669L11.3033 5.24264C11.5962 5.53553 11.5962
      6.01041 11.3033 6.3033C11.0104 6.59619 10.5355 6.59619 10.2426 6.3033L6 2.06066L1.75736 6.3033C1.46447 6.59619 0.989593
      6.59619 0.6967 6.3033C0.403806 6.01041 0.403806 5.53553 0.6967 5.24264L5.46967 0.469669ZM5.25 19L5.25 1L6.75 1L6.75 19L5.25 19Z"
          :fill="color"
      />
    </svg>
    <svg
        v-if="name === 'thumbs-up'"
        width="55"
        height="52"
        viewBox="0 0 55 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M2.00937 23.4663C1.9794 23.1211 2.02151 22.7734 2.13302 22.4454C2.24453 22.1173 2.42302 21.8161 2.65715 21.5606C2.89128 21.3052 3.17594 21.1013 3.49308 20.9617C3.81021 20.8222 4.15289 20.7501 4.49937 20.75H9.27312C9.93616 20.75 10.572 21.0134 11.0409 21.4822C11.5097 21.9511 11.7731 22.587 11.7731 23.25V47C11.7731 47.663 11.5097 48.2989 11.0409 48.7678C10.572 49.2366 9.93616 49.5 9.27312 49.5H6.56562C5.93991 49.5002 5.33688 49.2657 4.87565 48.8428C4.41441 48.42 4.12849 47.8396 4.07437 47.2163L2.00937 23.4663Z"
          fill="white"
          :stroke="color"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
      />
      <path
          d="M19.2734 22.4687C19.2734 21.4237 19.9234 20.4887 20.8672 20.0437C22.9284 19.0712 26.4397 17.1175 28.0234 14.4762C30.0647 11.0712 30.4497 4.91999 30.5122 3.51124C30.5209 3.31374 30.5159 3.11624 30.5422 2.92124C30.8809 0.479993 35.5922 3.33124 37.3984 6.34624C38.3797 7.98124 38.5047 10.13 38.4022 11.8087C38.2909 13.6037 37.7647 15.3375 37.2484 17.06L36.1484 20.7312H49.7197C50.1059 20.7312 50.487 20.8207 50.8328 20.9927C51.1787 21.1646 51.48 21.4144 51.7131 21.7224C51.9463 22.0303 52.1049 22.3881 52.1765 22.7677C52.2481 23.1472 52.2308 23.5382 52.1259 23.91L45.4134 47.68C45.2653 48.2042 44.9499 48.6657 44.5153 48.9943C44.0807 49.3228 43.5507 49.5004 43.0059 49.5H21.7734C21.1104 49.5 20.4745 49.2366 20.0057 48.7678C19.5368 48.2989 19.2734 47.663 19.2734 47V22.4687Z"
          fill="white"
          :stroke="color"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
      />
    </svg>
    <svg
        v-if="name === 'oops'"
        width="55"
        height="49"
        viewBox="0 0 55 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M23.2935 4.48906L2.47138 39.2499C2.04208 39.9933 1.81492 40.8363 1.81252 41.6948C1.81012 42.5533 2.03254 43.3974 2.45768 44.1433C2.88281 44.8891 3.49583 45.5106 4.23574 45.946C4.97566 46.3814 5.81668 46.6155 6.67513 46.6249H48.3193C49.1777 46.6155 50.0188 46.3814 50.7587 45.946C51.4986 45.5106 52.1116 44.8891 52.5368 44.1433C52.9619 43.3974 53.1843 42.5533 53.1819 41.6948C53.1795 40.8363 52.9524 39.9933 52.5231 39.2499L31.701 4.48906C31.2627 3.76657 30.6457 3.16923 29.9093 2.75467C29.173 2.3401 28.3422 2.12231 27.4972 2.12231C26.6522 2.12231 25.8214 2.3401 25.0851 2.75467C24.3488 3.16923 23.7317 3.76657 23.2935 4.48906V4.48906Z"
          :stroke="color"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
      />
      <path
          d="M27.5 17.125V26.9583"
          :stroke="color"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
      />
      <path
          d="M27.5 36.7915H27.5236"
          :stroke="color"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
      />
    </svg>
    <svg v-if="name === 'white-logo'" width="96" height="20" viewBox="0 0 96 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4146 0.169493C16.3725 0.266428 16.3584 4.26849 16.3725 9.07373L16.4146 17.7979L17.7341 17.8395C18.7027 17.8672 19.0957 17.8256 19.1799 17.701C19.2361 17.6041 19.2922 16.2608 19.2922 14.7099V11.9126H20.1064H20.9346L21.089 12.4942C21.1733 12.8265 21.3136 13.3666 21.3978 13.7128C21.4821 14.059 21.6365 14.6406 21.7488 15.0284C21.8611 15.4023 22.0576 16.1223 22.1839 16.6209C22.3243 17.1194 22.4928 17.6041 22.563 17.6872C22.6472 17.8118 23.1104 17.8672 23.9527 17.8672C24.9774 17.8672 25.216 17.8256 25.2862 17.6456C25.3704 17.4241 24.9634 15.6792 24.3317 13.5051C23.8404 11.8018 23.8404 11.6079 24.3317 11.2894C24.5703 11.1371 24.8089 10.9017 24.8511 10.7632C24.8932 10.6386 24.9774 10.5278 25.0476 10.5278C25.3564 10.5278 25.4827 8.86601 25.4406 5.43172C25.3985 2.05281 25.3845 1.84509 25.0897 1.38811C24.3457 0.197187 23.5737 0.00331688 19.4888 0.00331688C17.2147 0.00331688 16.4567 0.0448608 16.4146 0.169493ZM21.8892 2.96678C22.1839 3.09141 22.4085 3.29913 22.4507 3.50685C22.5068 3.68687 22.5208 4.90549 22.4928 6.2072C22.4366 9.10143 22.5068 9.01834 20.6258 8.96295L19.3624 8.93525L19.3203 6.02718C19.3063 4.43466 19.3203 3.04987 19.3484 2.95293C19.4466 2.70367 21.2715 2.71752 21.8892 2.96678Z" fill="white"/>
      <path d="M28.7057 0.169491C27.9477 0.335667 27.3862 0.72341 26.9651 1.38811L26.6562 1.87279V8.87986C26.6562 15.4715 26.6703 15.9146 26.923 16.3578C27.5266 17.4379 28.6917 17.8672 31.05 17.8672C32.931 17.8672 34.1382 17.5764 34.6857 16.9948C35.57 16.067 35.57 16.0254 35.57 8.86601V2.37132L35.2191 1.62353C34.7699 0.695713 34.2926 0.349514 33.2538 0.155643C32.2572 -0.0243797 29.562 -0.0243797 28.7057 0.169491ZM32.4818 3.1468C32.7625 3.50685 32.7766 3.60379 32.7345 9.00449C32.6923 13.9205 32.6643 14.516 32.4537 14.7237C32.1589 15.0284 30.1656 15.0561 29.7866 14.7652C29.548 14.5991 29.5339 14.1559 29.5339 8.93525C29.5339 2.28823 29.4076 2.8006 31.1061 2.78676C32.1028 2.77291 32.201 2.8006 32.4818 3.1468Z" fill="white"/>
      <path d="M40.2644 0.238731C40.2223 0.377211 40.124 0.640324 40.0539 0.834194C39.9837 1.02806 39.8714 1.52659 39.8012 1.94203C39.731 2.35747 39.5345 3.29913 39.366 4.01923C39.1976 4.73932 39.0151 5.63944 38.9449 5.99948C38.7905 6.78882 38.4255 8.43673 38.229 9.17067C38.1588 9.44763 38.0044 10.1262 37.8921 10.6939C37.6956 11.691 37.5412 12.4249 36.9797 14.9591C36.5305 16.9671 36.4182 17.6179 36.5024 17.7564C36.6007 17.9087 39.1835 17.8949 39.2958 17.7426C39.4081 17.5487 39.6468 16.4408 39.8152 15.2638L39.9696 14.2667H42.0191H44.0686L44.237 14.8068C44.3353 15.1114 44.4616 15.7208 44.5037 16.1639C44.5599 16.607 44.6862 17.1748 44.7985 17.4241L44.981 17.8672H46.2163C47.6621 17.8672 47.8727 17.7703 47.7323 17.2302C47.6762 17.0086 47.5358 16.427 47.4375 15.9285C47.3252 15.4299 47.1006 14.4329 46.9462 13.7128C46.7778 12.9927 46.5672 11.9957 46.483 11.4971C46.3847 10.9986 46.2724 10.5278 46.2303 10.4585C46.1742 10.3754 46.09 10.0015 46.0478 9.62765C45.9917 9.23991 45.8654 8.58905 45.7531 8.17361C45.5425 7.31504 45.3179 6.33183 45.0231 4.91934C44.1528 0.72341 44.0265 0.252581 43.8299 0.127949C43.7317 0.0587082 42.9035 0.00331688 41.991 0.00331688C40.5452 0.00331688 40.3346 0.0310116 40.2644 0.238731ZM42.6789 6.71958C42.7912 7.39813 42.9877 8.46442 43.0859 9.07373C43.1982 9.68304 43.3527 10.4308 43.4228 10.7355C43.7036 11.8018 43.7457 11.7741 42.1875 11.7741C41.4155 11.7741 40.7557 11.7325 40.7277 11.6633C40.6575 11.5664 40.854 10.2924 41.247 8.17361C41.3593 7.52276 41.5559 6.47031 41.6822 5.81946C42.0331 3.8669 42.0472 3.8392 42.2577 4.71162C42.37 5.12706 42.5525 6.02718 42.6789 6.71958Z" fill="white"/>
      <path d="M48.7216 0.101158C48.5952 0.211943 48.5952 17.3557 48.7075 17.6465C48.8339 17.9789 54.3225 17.965 55.4736 17.6465C56.4983 17.3557 57.13 16.7741 57.3967 15.874C57.7055 14.8354 57.7055 3.00923 57.3967 1.97063C57.0879 0.959732 56.4281 0.364269 55.4034 0.156549C54.5612 -0.00962448 48.876 -0.0650177 48.7216 0.101158ZM54.0137 2.96769C54.3366 3.09232 54.5331 3.30004 54.6454 3.60469C54.7436 3.90935 54.7998 5.66804 54.7998 8.96385C54.7998 15.2508 54.884 15.0154 52.6942 14.9323L51.6414 14.8908L51.5993 9.01925C51.5852 4.80947 51.6133 3.09232 51.7256 2.96769C51.9362 2.71842 53.396 2.71842 54.0137 2.96769Z" fill="white"/>
      <path d="M60.0983 0.101175C60.0421 0.142719 60 4.13093 60 8.95002V17.7296L60.3229 17.7989C60.4913 17.8404 61.137 17.8681 61.7547 17.8404L62.8777 17.7989L62.9198 14.8908C62.9479 12.7859 63.004 11.9689 63.1303 11.8858C63.2146 11.8304 64.0287 11.775 64.9271 11.775C67.2573 11.775 68.0013 11.4565 68.633 10.1963C68.9839 9.51779 68.9839 9.4624 68.9839 5.88962C68.9839 2.31685 68.9839 2.26146 68.633 1.58291C68.2259 0.765877 67.6925 0.33659 66.8503 0.142719C66.1765 -0.00960922 60.2386 -0.0511532 60.0983 0.101175ZM65.3904 2.92616C65.9659 3.18927 66.0642 3.64625 66.008 6.15273C65.9659 8.43765 65.9659 8.46534 65.615 8.7423C65.3202 8.96387 65.0675 9.00541 64.141 8.96387L63.018 8.93617L62.9759 5.97271C62.9479 3.6878 62.99 2.98155 63.1163 2.88461C63.383 2.71844 64.9833 2.74614 65.3904 2.92616Z" fill="white"/>
      <path d="M73.9208 0.170565C73.8225 0.2675 73.7523 0.461372 73.7523 0.599852C73.7523 0.752178 73.626 1.40303 73.4716 2.06774C73.0925 3.70179 72.8258 4.87887 72.5872 6.02825C71.8994 9.18559 71.759 9.82259 71.6327 10.2796C71.5625 10.5565 71.4081 11.2351 71.2958 11.8029C71.1975 12.3568 71.001 13.243 70.8887 13.7831C70.7764 14.3093 70.5518 15.3341 70.4114 16.0403C70.271 16.7466 70.1026 17.3559 70.0605 17.4113C70.0043 17.4528 70.0324 17.5775 70.1166 17.6882C70.2289 17.8129 70.65 17.8683 71.5063 17.8683C72.9662 17.8683 73.1206 17.7575 73.261 16.6219C73.3171 16.2065 73.4294 15.5141 73.5277 15.0987L73.6962 14.337L75.7597 14.2955L77.8091 14.2539L77.8933 14.7802C77.9355 15.071 78.0337 15.5003 78.1039 15.7218C78.1741 15.9572 78.2864 16.4558 78.3285 16.8297C78.3847 17.2174 78.4829 17.6051 78.5671 17.6882C78.7496 17.9375 81.2904 17.9375 81.3606 17.7021C81.4027 17.6051 81.3325 17.0928 81.2343 16.5666C81.0377 15.5972 80.9395 15.1125 80.3499 12.4676C80.0551 11.152 79.7884 9.94723 79.5217 8.65937C79.255 7.46844 78.9602 6.0698 78.6654 4.78194C78.511 4.08954 78.2864 3.06479 78.1601 2.49702C78.0478 1.92926 77.8512 1.12607 77.7389 0.724483L77.5284 0.00438881H75.8018C74.6647 0.00438881 74.0331 0.0597801 73.9208 0.170565ZM76.0966 5.44664C76.265 6.416 76.5036 7.67616 76.8405 9.55948C76.9248 10.058 77.0651 10.7089 77.1213 10.9997C77.1915 11.3043 77.2195 11.5951 77.1774 11.6644C77.1353 11.7198 76.4756 11.7752 75.6895 11.7752H74.2857L74.3559 11.2489C74.398 10.972 74.5244 10.2103 74.6507 9.55948C75.2964 6.13904 75.4789 5.15583 75.5351 4.67115C75.6474 3.88182 75.886 4.22802 76.0966 5.44664Z" fill="white"/>
      <path d="M82.5452 0.224884C82.503 0.349516 82.4609 4.28234 82.4609 8.99064C82.4609 15.4576 82.503 17.5764 82.6294 17.701C82.7557 17.8256 83.626 17.8672 85.6755 17.8672C89.0445 17.8672 89.9148 17.701 90.6728 16.9394C91.5151 16.0808 91.5853 15.4715 91.5853 8.93525C91.5853 2.17745 91.5291 1.67892 90.5605 0.806498C89.7885 0.100252 89.1708 0.00331688 85.6334 0.00331688C83.0224 0.00331688 82.6153 0.0310116 82.5452 0.224884ZM87.9776 2.93908C88.6374 3.27144 88.6234 3.25759 88.6374 8.90756V14.2806L88.2865 14.6268C87.9636 14.9453 87.8513 14.973 86.7845 14.9314L85.6194 14.8899L85.5772 8.82447L85.5492 2.77291H86.616C87.1915 2.77291 87.8092 2.84215 87.9776 2.93908Z" fill="white"/>
      <path d="M92.7868 0.224884C92.6324 0.626474 92.6885 17.3133 92.8429 17.6041C92.9693 17.8395 93.1518 17.8672 94.2186 17.8672C94.9907 17.8672 95.5101 17.8118 95.6224 17.701C95.7487 17.5764 95.7908 15.4161 95.7908 8.85216C95.7908 1.06961 95.7627 0.169493 95.5662 0.0864048C95.4399 0.0448608 94.7941 0.00331688 94.1063 0.00331688C93.1097 0.00331688 92.857 0.0448608 92.7868 0.224884Z" fill="white"/>
      <path d="M0 4.61768V9.23924L0.465475 8.76029C1.6617 7.52097 3.3284 6.44458 4.96507 5.84714C5.18029 5.76813 5.35547 5.67926 5.35547 5.65457C5.35547 5.62495 5.20531 5.17563 5.02513 4.65225C4.83994 4.12887 4.6998 3.68942 4.71481 3.67955C4.73483 3.65486 5.21032 3.86718 6.88203 4.63743C7.18734 4.77569 7.68785 5.00775 7.99816 5.15094C8.40858 5.33857 8.55874 5.42744 8.53872 5.48176C8.52871 5.52126 8.30348 6.01007 8.03821 6.56802C7.76793 7.12596 7.36752 7.97522 7.14229 8.45416C6.91706 8.93804 6.71686 9.33305 6.70184 9.33305C6.68683 9.33305 6.65179 9.25899 6.62176 9.17011C6.24638 8.04928 6.14127 7.80241 6.03616 7.80241C5.84096 7.80734 5.08519 7.99003 4.72983 8.12335C3.10817 8.72573 1.72676 9.98481 0.91093 11.6043C0.415424 12.577 0.140143 13.5201 0.0750766 14.4681C0.0550562 14.7002 0.0350358 14.9767 0.0250255 15.0853C0 15.3964 0.0950971 16.3691 0.200204 16.8924C0.335342 17.5343 0.510521 18.0429 0.825843 18.6947C1.10613 19.2822 1.38642 19.7315 1.33136 19.5044C1.12114 18.6552 1.02605 17.5442 1.09612 16.7591C1.2763 14.8187 2.03708 13.1498 3.34842 11.8512C5.9761 9.24417 10.0302 9.22442 12.6629 11.8018C12.9282 12.0586 13.2185 12.3696 13.3086 12.4931C13.5088 12.7647 13.4888 12.661 13.2085 12.0191C12.9682 11.4759 12.6529 10.9328 12.3126 10.4934C12.1774 10.3156 12.0623 10.1379 12.0623 10.1033C12.0623 10.0687 12.1324 9.97987 12.2125 9.90087C12.6579 9.48118 13.2685 8.35048 13.4888 7.55553C13.6039 7.11609 13.6139 7.0124 13.6139 5.9212C13.6139 4.77569 13.6089 4.74606 13.4637 4.24243C13.2936 3.64005 12.8681 2.77598 12.5178 2.32172C12.1874 1.88721 11.4617 1.21077 11.0363 0.934267C10.5608 0.623201 10.2354 0.470137 9.71992 0.297323C8.82401 0.00107002 8.79898 0.00107002 4.19929 0.00107002H0V4.61768Z" fill="#FDFFFC"/>
    </svg>
    <svg width="15" height="17" v-if="name === 'menu'" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.667 5.8345C1.22488 5.8345 0.800876 5.65887 0.488253 5.34625C0.17563 5.03362 0 4.60962 0 4.1675V1.667C0 1.22488 0.17563 0.800876
      0.488253 0.488253C0.800876 0.17563 1.22488 0 1.667 0H5.001C5.44312 0 5.86712 0.17563 6.17975 0.488253C6.49237 0.800876 6.668 1.22488
       6.668 1.667V4.1675C6.668 4.60962 6.49237 5.03362 6.17975 5.34625C5.86712 5.65887 5.44312 5.8345 5.001 5.8345H1.667ZM1.667
       4.1675H5.001V1.667H1.667V4.1675ZM1.667 16.67C1.22488 16.67 0.800876 16.4944 0.488253 16.1817C0.17563 15.8691 0 15.4451 0
        15.003V8.335C0 7.89288 0.17563 7.46888 0.488253 7.15625C0.800876 6.84363 1.22488 6.668 1.667 6.668H5.001C5.44312 6.668 5.86712
        6.84363 6.17975 7.15625C6.49237 7.46888 6.668 7.89288 6.668 8.335V15.003C6.668 15.4451 6.49237 15.8691 6.17975 16.1817C5.86712
         16.4944 5.44312 16.67 5.001 16.67H1.667ZM1.667 15.003H5.001V8.335H1.667V15.003ZM8.335 15.003C8.335 15.4451 8.51063 15.8691 8.82325 16.1817C9.13588 16.4944 9.55988 16.67 10.002 16.67H13.336C13.7781
         16.67 14.2021 16.4944 14.5147 16.1817C14.8274 15.8691 15.003 15.4451 15.003 15.003V13.336C15.003 12.8939 14.8274 12.4699 14.5147 12.1573C14.2021 11.8446 13.7781 11.669 13.336 11.669H10.002C9.55988 11.669
         9.13588 11.8446 8.82325 12.1573C8.51063 12.4699 8.335 12.8939 8.335 13.336V15.003ZM13.336 15.003H10.002V13.336H13.336V15.003ZM10.002 10.002C9.55988 10.002 9.13588 9.82637 8.82325 9.51375C8.51063 9.20112 8.335 8.77712
         8.335 8.335V1.667C8.335 1.22488 8.51063 0.800876 8.82325 0.488253C9.13588 0.17563 9.55988 0 10.002 0H13.336C13.7781 0 14.2021 0.17563 14.5147 0.488253C14.8274 0.800876 15.003 1.22488 15.003 1.667V8.335C15.003 8.77712 14.8274 9.20112 14.5147 9.51375C14.2021 9.82637 13.7781 10.002 13.336 10.002H10.002ZM10.002 8.335H13.336V1.667H10.002V8.335Z" :fill="color"/>
    </svg>

    <svg v-if="name === 'company'" :width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.75 2.5V16.25H11.25V2.5H3.75ZM3.125 1.25H11.875C12.0408 1.25 12.1997 1.31585 12.3169 1.43306C12.4342 1.55027 12.5 1.70924 12.5 1.875V16.875C12.5 17.0408 12.4342 17.1997 12.3169 17.3169C12.1997 17.4342 12.0408 17.5 11.875 17.5H3.125C2.95924 17.5 2.80027 17.4342 2.68306 17.3169C2.56585 17.1997 2.5 17.0408 2.5 16.875V1.875C2.5 1.70924 2.56585 1.55027 2.68306 1.43306C2.80027 1.31585 2.95924 1.25 3.125 1.25Z" :fill="color"/>
      <path d="M5 5H10V6.25H5V5ZM5 8.75H10V10H5V8.75ZM5 12.5H10V13.75H5V12.5ZM12.5 10H15V11.25H12.5V10ZM12.5 12.5H15V13.75H12.5V12.5ZM1.25 16.25H18.75V17.5H1.25V16.25Z" :fill="color"/>
      <path d="M12.5 7.5V16.25H16.25V7.5H12.5ZM11.875 6.25H16.875C17.0408 6.25 17.1997 6.31585 17.3169 6.43306C17.4342 6.55027 17.5 6.70924 17.5 6.875V16.875C17.5 17.0408 17.4342 17.1997 17.3169 17.3169C17.1997 17.4342 17.0408 17.5 16.875 17.5H11.875C11.7092 17.5 11.5503 17.4342 11.4331 17.3169C11.3158 17.1997 11.25 17.0408 11.25 16.875V6.875C11.25 6.70924 11.3158 6.55027 11.4331 6.43306C11.5503 6.31585 11.7092 6.25 11.875 6.25Z" :fill="color"/>
    </svg>
    <svg v-if="name === 'customers'" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 1.66602C9.17591 1.66602 8.37033 1.91039 7.68512 2.36823C6.99992 2.82606 6.46587 3.47681 6.1505 4.23817C5.83514
      4.99953 5.75262 5.8373 5.91339 6.64556C6.07417 7.45381 6.471 8.19624 7.05372 8.77896C7.63644 9.36168 8.37887 9.75852
      9.18712 9.91929C9.99538 10.0801 10.8332 9.99755 11.5945 9.68218C12.3559 9.36682 13.0066 8.83276 13.4645 8.14756C13.9223
      7.46235 14.1667 6.65677 14.1667 5.83268C14.1667 4.72761 13.7277 3.66781 12.9463 2.8864C12.1649 2.105 11.1051 1.66602 10
      1.66602ZM10 8.33268C9.50555 8.33268 9.0222 8.18606 8.61107 7.91136C8.19995 7.63665 7.87952 7.24621 7.6903 6.78939C7.50108 6.33258
      7.45157 5.82991 7.54804 5.34496C7.6445 4.86 7.8826 4.41455 8.23223 4.06492C8.58186 3.71528 9.02732 3.47718 9.51227 3.38072C9.99723
      3.28426 10.4999 3.33376 10.9567 3.52298C11.4135 3.7122 11.804 4.03263 12.0787 4.44376C12.3534 4.85488 12.5 5.33823 12.5 5.83268C12.5
      6.49572 12.2366 7.13161 11.7678 7.60045C11.2989 8.06929 10.663 8.33268 10 8.33268ZM17.5 17.4993V16.666C17.5 15.1189 16.8854 13.6352 15.7915 12.5412C14.6975
       11.4473 13.2138 10.8327 11.6667 10.8327H8.33333C6.78624 10.8327 5.30251 11.4473 4.20854 12.5412C3.11458 13.6352 2.5 15.1189 2.5 16.666V17.4993H4.16667V16.666C4.16667
        15.5609 4.60565 14.5011 5.38705 13.7197C6.16846 12.9383 7.22826 12.4993 8.33333 12.4993H11.6667C12.7717 12.4993 13.8315 12.9383 14.6129 13.7197C15.3943 14.5011 15.8333 15.5609 15.8333 16.666V17.4993H17.5Z" :fill="color"/>
    </svg>
    <svg v-if="name === 'booking'" width="16" height="16" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4974 3.16602H2.4974C2.03716 3.16602 1.66406 3.53911 1.66406 3.99935V14.8327C1.66406 15.2929 2.03716 15.666 2.4974 15.666H17.4974C17.9576 15.666 18.3307 15.2929 18.3307 14.8327V3.99935C18.3307 3.53911 17.9576 3.16602 17.4974 3.16602Z" :stroke="color" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.82812 1.5V4.83333M10.4115 8.58333H5.82812M14.1615 11.9167H5.82812M14.1615 1.5V4.83333" :stroke="color" stroke-width="1.66667" stroke-linecap="round"/>
    </svg>


    <svg v-if="name === 'start'" :width="width" :height="height" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 2.33325V33.1666" :stroke="color" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17 5.6665H29.9542L32 8.99984L29.9542 12.3332H17V5.6665ZM17 17.3332H4.04583L2 20.6665L4.04583 23.9998H17V17.3332Z"
            :fill="color" :stroke="color" stroke-width="4" stroke-linejoin="round"/>
      <path d="M10.334 34H23.6673" :stroke="color" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg  v-if="name === 'end'" :width="width" :height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.75 0V18H2.25V12H17.25V0H0.75ZM2.25 1.5H4.5V3.75H6.75V1.5H9V3.75H11.25V1.5H13.5V3.75H15.75V6H13.5V8.25H15.75V10.5H13.5V8.25H11.25V10.5H9V8.25H6.75V10.5H4.5V8.25H2.25V6H4.5V3.75H2.25V1.5ZM4.5 6V8.25H6.75V6H4.5ZM6.75 6H9V3.75H6.75V6ZM9 6V8.25H11.25V6H9ZM11.25 6H13.5V3.75H11.25V6Z" :fill="color"/>
    </svg>

    <svg v-if="name === 'key'" width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 4H11.65C11.2381 2.83048 10.4733 1.81762 9.46134 1.10116C8.44934 0.384703 7.23994 -4.57935e-05 6 4.08807e-09C2.69 4.08807e-09 0 2.69 0 6C0 9.31 2.69 12 6 12C7.23994 12 8.44934 11.6153 9.46134 10.8988C10.4733 10.1824 11.2381 9.16952 11.65 8H12L14 10L16 8L18 10L22 5.96L20 4ZM6 9C4.35 9 3 7.65 3 6C3 4.35 4.35 3 6 3C7.65 3 9 4.35 9 6C9 7.65 7.65 9 6 9Z"
            :fill="color"/>
    </svg>

    <svg v-if="name === 'switch'" width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="12" rx="6" :fill="color"/>
      <circle cx="17.9984" cy="6.00039" r="3.6" fill="white"/>
    </svg>

    <svg v-if="name === 'change-password'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7336_3433)">
        <path d="M11.7352 11.7327H10.6685V10.666H11.7352V11.7327ZM8.53516 11.7327H9.60182V10.666H8.53516V11.7327ZM13.8685 11.7327H12.8018V10.666H13.8685V11.7327Z"
              :fill="color"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.2 6.4V3.73333C3.2 3.24307 3.29657 2.7576 3.48418 2.30465C3.6718 1.8517 3.9468 1.44014 4.29347 1.09347C4.64014 0.746796 5.0517 0.471801 5.50465 0.284183C5.9576 0.0965655 6.44307 0 6.93333 0C7.4236 0 7.90907 0.0965655 8.36202 0.284183C8.81497 0.471801 9.22653 0.746796 9.5732 1.09347C9.91987 1.44014 10.1949 1.8517 10.3825 2.30465C10.5701 2.7576 10.6667 3.24307 10.6667 3.73333V6.4H12.2667C12.691 6.4 13.098 6.56857 13.398 6.86863C13.6981 7.16869 13.8667 7.57565 13.8667 8V8.58667C14.4695 8.70907 15.0115 9.03612 15.4007 9.5124C15.79 9.98867 16.0027 10.5849 16.0027 11.2C16.0027 11.8151 15.79 12.4113 15.4007 12.8876C15.0115 13.3639 14.4695 13.6909 13.8667 13.8133V14.4C13.8667 14.8243 13.6981 15.2313 13.398 15.5314C13.098 15.8314 12.691 16 12.2667 16H1.6C1.17565 16 0.768687 15.8314 0.468629 15.5314C0.168571 15.2313 0 14.8243 0 14.4L0 8C0 7.57565 0.168571 7.16869 0.468629 6.86863C0.768687 6.56857 1.17565 6.4 1.6 6.4H3.2ZM4.26667 3.73333C4.26667 3.02609 4.54762 2.34781 5.04772 1.84772C5.54781 1.34762 6.22609 1.06667 6.93333 1.06667C7.64058 1.06667 8.31886 1.34762 8.81895 1.84772C9.31905 2.34781 9.6 3.02609 9.6 3.73333V6.4H4.26667V3.73333ZM9.06667 9.6C8.64232 9.6 8.23535 9.76857 7.9353 10.0686C7.63524 10.3687 7.46667 10.7757 7.46667 11.2C7.46667 11.6243 7.63524 12.0313 7.9353 12.3314C8.23535 12.6314 8.64232 12.8 9.06667 12.8H13.3333C13.7577 12.8 14.1646 12.6314 14.4647 12.3314C14.7648 12.0313 14.9333 11.6243 14.9333 11.2C14.9333 10.7757 14.7648 10.3687 14.4647 10.0686C14.1646 9.76857 13.7577 9.6 13.3333 9.6H9.06667Z"
              :fill="color"/>
      </g>
      <defs>
        <clipPath id="clip0_7336_3433">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>

    <svg v-if="name === 'settings'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5 15.8333H7.5M2.5 4.16667H5.83333H2.5ZM17.5 4.16667H9.16667H17.5ZM2.5 10H12.5H2.5ZM17.5 10H15.8333H17.5ZM2.5 15.8333H4.16667H2.5Z" :stroke="color" stroke-width="1.66667" stroke-linecap="round"/>
      <path d="M7.5026 5.83333C8.42308 5.83333 9.16927 5.08714 9.16927 4.16667C9.16927 3.24619 8.42308 2.5 7.5026 2.5C6.58213 2.5 5.83594 3.24619 5.83594 4.16667C5.83594 5.08714 6.58213 5.83333 7.5026 5.83333Z" :stroke="color" stroke-width="1.66667" stroke-linecap="round"/>
      <path d="M14.1667 11.6667C15.0871 11.6667 15.8333 10.9205 15.8333 9.99999C15.8333 9.07952 15.0871 8.33333 14.1667 8.33333C13.2462 8.33333 12.5 9.07952 12.5 9.99999C12.5 10.9205 13.2462 11.6667 14.1667 11.6667Z" :stroke="color" stroke-width="1.66667" stroke-linecap="round"/>
      <path d="M5.83073 17.5C6.7512 17.5 7.4974 16.7538 7.4974 15.8333C7.4974 14.9129 6.7512 14.1667 5.83073 14.1667C4.91025 14.1667 4.16406 14.9129 4.16406 15.8333C4.16406 16.7538 4.91025 17.5 5.83073 17.5Z" :stroke="color" stroke-width="1.66667" stroke-linecap="round"/>
    </svg>

    <svg v-if="name === 'manifest'" width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5469 8.15625C10.3231 8.15625 10.1085 8.06736 9.95025 7.90912C9.79202 7.75089 9.70312 7.53628 9.70312 7.3125C9.70312 7.08872 9.79202
       6.87411 9.95025 6.71588C10.1085 6.55764 10.3231 6.46875 10.5469 6.46875C10.7706 6.46875 10.9853 6.55764 11.1435 6.71588C11.3017 6.87411 11.3906
        7.08872 11.3906 7.3125C11.3906 7.53628 11.3017 7.75089 11.1435 7.90912C10.9853 8.06736 10.7706 8.15625 10.5469 8.15625ZM10.5469 11.5312C10.3231
        11.5312 10.1085 11.4424 9.95025 11.2841C9.79202 11.1259 9.70312 10.9113 9.70312 10.6875C9.70312 10.4637 9.79202 10.2491 9.95025 10.0909C10.1085
        9.93264 10.3231 9.84375 10.5469 9.84375C10.7706 9.84375 10.9853 9.93264 11.1435 10.0909C11.3017 10.2491 11.3906 10.4637 11.3906 10.6875C11.3906
         10.9113 11.3017 11.1259 11.1435 11.2841C10.9853 11.4424 10.7706 11.5312 10.5469 11.5312ZM9.70312 14.0625C9.70312 14.2863 9.79202 14.5009 9.95025
          14.6591C10.1085 14.8174 10.3231 14.9062 10.5469 14.9062C10.7706 14.9062 10.9853 14.8174 11.1435 14.6591C11.3017 14.5009 11.3906 14.2863 11.3906
           14.0625C11.3906 13.8387 11.3017 13.6241 11.1435 13.4659C10.9853 13.3076 10.7706 13.2188 10.5469 13.2188C10.3231 13.2188 10.1085 13.3076 9.95025
            13.4659C9.79202 13.6241 9.70312 13.8387 9.70312 14.0625ZM8.57812 7.3125C8.57812 7.16332 8.51886 7.02024 8.41337 6.91475C8.30788 6.80926 8.16481
            6.75 8.01562 6.75H4.07812C3.92894 6.75 3.78587 6.80926 3.68038 6.91475C3.57489 7.02024 3.51562 7.16332 3.51562 7.3125C3.51562 7.46168 3.57489
            7.60476 3.68038 7.71025C3.78587 7.81574 3.92894 7.875 4.07812 7.875H8.01562C8.16481 7.875 8.30788 7.81574 8.41337 7.71025C8.51886 7.60476 8.57812
             7.46168 8.57812 7.3125ZM8.57812 10.6875C8.57812 10.5383 8.51886 10.3952 8.41337 10.2898C8.30788 10.1843 8.16481 10.125 8.01562
             10.125H4.07812C3.92894 10.125 3.78587 10.1843 3.68038 10.2898C3.57489 10.3952 3.51562 10.5383 3.51562 10.6875C3.51562 10.8367 3.57489 10.9798
             3.68038 11.0852C3.78587 11.1907 3.92894 11.25 4.07812 11.25H8.01562C8.16481 11.25 8.30788 11.1907 8.41337 11.0852C8.51886 10.9798 8.57812
             10.8367 8.57812 10.6875ZM8.57812 14.0625C8.57812 13.9133 8.51886 13.7702 8.41337 13.6648C8.30788 13.5593 8.16481 13.5 8.01562 13.5H4.07812C3.92894 13.5 3.78587 13.5593 3.68038 13.6648C3.57489 13.7702 3.51562 13.9133 3.51562 14.0625C3.51562 14.2117 3.57489 14.3548 3.68038 14.4602C3.78587 14.5657 3.92894 14.625 4.07812 14.625H8.01562C8.16481 14.625 8.30788 14.5657 8.41337 14.4602C8.51886 14.3548 8.57812 14.2117 8.57812 14.0625ZM5.76562 2.32016e-07C5.41651 -0.00018279 5.07593 0.107919 4.79082 0.309407C4.50572 0.510895 4.29012 0.79585 4.17375 1.125H2.39062C1.94307 1.125 1.51385 1.30279 1.19738 1.61926C0.880914 1.93573 0.703125 2.36495 0.703125 2.8125V16.3125C0.703125 16.7601 0.880914 17.1893 1.19738 17.5057C1.51385 17.8222 1.94307 18 2.39062 18H12.5156C12.9632 18 13.3924 17.8222 13.7089 17.5057C14.0253 17.1893 14.2031 16.7601 14.2031 16.3125V2.8125C14.2031 2.36495 14.0253 1.93573 13.7089 1.61926C13.3924 1.30279 12.9632 1.125 12.5156 1.125H10.7325C10.6161 0.79585 10.4005 0.510895 10.1154 0.309407C9.83032 0.107919 9.48974 -0.00018279 9.14062 2.32016e-07H5.76562ZM5.20312 1.6875C5.20312 1.53832 5.26239 1.39524 5.36788 1.28975C5.47337 1.18426 5.61644 1.125 5.76562 1.125H9.14062C9.28981 1.125 9.43288 1.18426 9.53837 1.28975C9.64386 1.39524 9.70312 1.53832 9.70312 1.6875C9.70312 1.83668 9.64386 1.97976 9.53837 2.08525C9.43288 2.19074 9.28981 2.25 9.14062 2.25H5.76562C5.61644 2.25 5.47337 2.19074 5.36788 2.08525C5.26239 1.97976 5.20312 1.83668 5.20312 1.6875ZM2.39062 2.25H4.17375C4.29012 2.57915 4.50572 2.86411 4.79082 3.06559C5.07593 3.26708 5.41651 3.37518 5.76562 3.375H9.14062C9.48974 3.37518 9.83032 3.26708 10.1154 3.06559C10.4005 2.86411 10.6161 2.57915 10.7325 2.25H12.5156C12.6648 2.25 12.8079 2.30926 12.9134 2.41475C13.0189 2.52024 13.0781 2.66332 13.0781 2.8125V16.3125C13.0781 16.4617 13.0189 16.6048 12.9134 16.7102C12.8079 16.8157 12.6648 16.875 12.5156 16.875H2.39062C2.24144 16.875 2.09837 16.8157 1.99288 16.7102C1.88739 16.6048 1.82812 16.4617
             1.82812 16.3125V2.8125C1.82812 2.66332 1.88739 2.52024 1.99288 2.41475C2.09837 2.30926 2.24144 2.25 2.39062 2.25Z" :fill="color"/>
    </svg>


    <svg width="17" height="114" v-if="name === 'to-and-fro'" viewBox="0 0 17 114" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.00295 5.60026C2.00295 1.30967 6.93611 -1.39339 11.9681 0.751898C13.1048 1.23682 14.0346 2.04959 14.6136 3.06452C15.8798 5.29991 15.3197 7.85281 14.1758 9.97236L10.2594 19.0169C10.1369 19.3063 9.92113 19.5551 9.6405 19.7306C9.35986 19.9061 9.02752 20 8.68719 20C8.34687 20 8.01453 19.9061 7.73389 19.7306C7.45326 19.5551 7.23749 19.3063 7.11499 19.0169L3.2786 9.92516C1.94646 7.14487 2.00295 5.60026 2.00295 5.60026ZM6.71017 9.32449C10.0947 11.1179 13.5215 7.80562 11.6669 4.53619C11.4236 4.10484 11.0515 3.74492 10.5936 3.49787C7.20913 1.70441 3.78228 5.01674 5.63692 8.28616C5.88532 8.71981 6.26412 9.07999 6.72899 9.32449H6.71017Z" fill="#959595"/>
      <circle cx="8.5" cy="105.5" r="4.5" fill="#757373"/>
      <circle cx="8.5" cy="105.5" r="8.07407" stroke="#919191" stroke-width="0.851852"/>
      <line x1="8.5" y1="31" x2="8.5" y2="88" stroke="#959595" stroke-dasharray="2 2"/>
    </svg>

    <svg width="28" height="51" v-if="name === 'to-and-fro-small'" viewBox="0 0 28 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14.5" cy="14.5" r="4.5" fill="#1E5770"/>
      <g filter="url(#filter0_f_12376_4078)">
        <path d="M22 14C22 18.4183 18.4183 22 14 22C9.58172 22 6 18.4183 6 14C6 9.58172 9.58172 6 14 6C18.4183 6 22 9.58172 22 14ZM7.60141 14C7.60141 17.5338 10.4662 20.3986 14 20.3986C17.5338 20.3986 20.3986 17.5338 20.3986 14C20.3986 10.4662 17.5338 7.60141 14 7.60141C10.4662 7.60141 7.60141 10.4662 7.60141 14Z" fill="#1E5770"/>
      </g>
      <line x1="14.5" y1="27" x2="14.5" y2="37" stroke="#1E5770" stroke-dasharray="2 2"/>
      <path d="M17.8284 48.0711L15 51L12.1716 48.0711C11.6122 47.4918 11.2312 46.7537 11.0769 45.9502C10.9225 45.1467 11.0017 44.3139 11.3045 43.557C11.6072 42.8001 12.1199 42.1532 12.7777 41.6981C13.4355 41.2429 14.2089 41 15 41C15.7911 41 16.5645 41.2429 17.2223 41.6981C17.8801 42.1532 18.3928 42.8001 18.6955 43.557C18.9983 44.3139 19.0775 45.1467 18.9231 45.9502C18.7688 46.7537 18.3878 47.4918 17.8284 48.0711ZM15 46.0626C15.2357 46.0626 15.4618 45.9656 15.6285 45.793C15.7952 45.6204 15.8889 45.3862 15.8889 45.1421C15.8889 44.898 15.7952 44.6638 15.6285 44.4912C15.4618 44.3186 15.2357 44.2216 15 44.2216C14.7643 44.2216 14.5382 44.3186 14.3715 44.4912C14.2048 44.6638 14.1111 44.898 14.1111 45.1421C14.1111 45.3862 14.2048 45.6204 14.3715 45.793C14.5382 45.9656 14.7643 46.0626 15 46.0626Z" fill="#1E5770"/>
      <defs>
        <filter id="filter0_f_12376_4078" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_12376_4078"/>
        </filter>
      </defs>
    </svg>


    <svg v-if="name === 'distance-icon'" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.41333 0.888889L1.77778 0.888889C0.795555 0.888889 -2.76062e-07 1.68444 -2.33127e-07 2.66667C-1.90193e-07 3.64889 0.795555 4.44444 1.77778 4.44444L6.22222 4.44444C6.71111 4.44444 7.11111 4.84444 7.11111 5.33333C7.11111 5.82222 6.71111 6.22222 6.22222 6.22222L2.58667 6.22222C2.4 5.70667 1.91111 5.33333 1.33333 5.33333C0.595555 5.33333 -9.05311e-08 5.92889 -5.82819e-08 6.66667C-2.60326e-08 7.40444 0.595556 8 1.33333 8C1.91111 8 2.4 7.62667 2.58667 7.11111L6.22222 7.11111C7.20444 7.11111 8 6.31556 8 5.33333C8 4.35111 7.20444 3.55556 6.22222 3.55556L1.77778 3.55556C1.28889 3.55556 0.888889 3.15556 0.888889 2.66667C0.888889 2.17778 1.28889 1.77778 1.77778 1.77778L5.41333 1.77778C5.5182 2.07376 5.72429 2.32317 5.99522 2.48193C6.26614 2.64069 6.58445 2.6986 6.89393 2.64543C7.20341 2.59225 7.48414 2.43141 7.68653 2.19133C7.88892 1.95124 7.99995 1.64735 8 1.33333C8 0.595555 7.40444 -3.23659e-07 6.66667 -2.91409e-07C6.08889 -2.66154e-07 5.6 0.373333 5.41333 0.888889Z" fill="#4F4F4F"/>
    </svg>

    <svg v-if="name=== 'alert-icon'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7999 13.5829L12.4083 2.98292C12.1498 2.5786 11.7937 2.24585 11.3728 2.01536C10.9519 1.78488 10.4798 1.66406 9.99992 1.66406C9.52005 1.66406 9.04791 1.78488 8.62702 2.01536C8.20613 2.24585 7.85004 2.5786 7.59159 2.98292L1.19992 13.5829C0.974132 13.9593 0.851411 14.3885 0.844097 14.8273C0.836783 15.2662 0.945133 15.6992 1.15825 16.0829C1.40465 16.5148 1.7613 16.8735 2.19176 17.1224C2.62221 17.3713 3.11103
      17.5015 3.60825 17.4996H16.3916C16.8855 17.5049 17.3722 17.3797 17.8023 17.1369C18.2325 16.894 18.591 16.5419 18.8416 16.1162C19.061 15.7286 19.1728 15.2893 19.1655 14.8439C19.1581 14.3985 19.0319 13.9632 18.7999 13.5829ZM17.3833 15.2912C17.2831 15.4634 17.1378 15.6049 16.963 15.7004C16.7882 15.7958 16.5906
       15.8417 16.3916 15.8329H3.60825C3.40927 15.8417 3.21168 15.7958 3.03689 15.7004C2.86209 15.6049 2.71674 15.4634 2.61659 15.2912C2.54345 15.1646 2.50494 15.0209 2.50494 14.8746C2.50494 14.7283 2.54345 14.5846 2.61659 14.4579L9.01659 3.84958C9.13159 3.69767 9.28023 3.57447 9.45084 3.48964C9.62144 3.40482 9.80939
        3.36067 9.99992 3.36067C10.1905 3.36067 10.3784 3.40482 10.549 3.48964C10.7196 3.57447 10.8683 3.69767 10.9833 3.84958L17.3749 14.4496C17.4509 14.5765 17.4917 14.7214 17.4932 14.8693C17.4946 15.0172 17.4567 15.1628 17.3833 15.2912Z" :fill="color"/>
      <path d="M9.9974 14.1667C10.4576 14.1667 10.8307 13.7936 10.8307 13.3333C10.8307 12.8731 10.4576 12.5 9.9974 12.5C9.53716 12.5 9.16406 12.8731 9.16406 13.3333C9.16406 13.7936 9.53716 14.1667 9.9974 14.1667Z" :fill="color"/>
      <path d="M9.9974 6.66602C9.77638 6.66602 9.56442 6.75381 9.40814 6.91009C9.25186 7.06637 9.16406 7.27834 9.16406 7.49935V10.8327C9.16406 11.0537 9.25186 11.2657 9.40814 11.4219C9.56442 11.5782 9.77638 11.666 9.9974 11.666C10.2184 11.666 10.4304 11.5782 10.5867 11.4219C10.7429 11.2657 10.8307 11.0537 10.8307 10.8327V7.49935C10.8307 7.27834 10.7429 7.06637 10.5867 6.91009C10.4304 6.75381 10.2184 6.66602 9.9974 6.66602Z" :fill="color"/>
    </svg>

    <svg v-if="name === 'wallet-with-card'" width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39.479 7.13542C39.537 7.06244 39.6092 7.00203 39.6913 6.95784C39.7734 6.91365 39.8636 6.88662 39.9565 6.87839C40.0494 6.87016 40.1429 6.8809 40.2315
       6.90996C40.3201 6.93902 40.4018 6.98578 40.4717 7.04742L43.0045 9.28042L35.0323 19.2492H40.315L46.1065 12.0112L50.6495 16.0179C50.7838 16.1366 50.8665 16.3031 50.8799
       16.4819C50.8932 16.6607 50.8362 16.8376 50.721 16.9749L48.8235 19.2492H54.1695C54.8317 18.2797 55.1167 17.1017 54.9708 15.9367C54.825 14.7718 54.2583 13.7004 53.3775
        12.9242L43.2025 3.95092C42.7116 3.51863 42.1378 3.19093 41.5161 2.98785C40.8943 2.78477 40.2377 2.71055 39.5863 2.76975C38.9349 2.82894 38.3024 3.0203 37.7275 3.33213C37.1525
         3.64397 36.6471 4.06973 36.2423 4.58342L24.684 19.2492H29.9365L39.479 7.13542ZM44.6875 38.4992C44.1405 38.4992 43.6159 38.7165 43.2291 39.1033C42.8423 39.4901 42.625 40.0147
         42.625 40.5617C42.625 41.1087 42.8423 41.6333 43.2291 42.0201C43.6159 42.4069 44.1405 42.6242 44.6875 42.6242H50.1875C50.7345 42.6242 51.2591 42.4069 51.6459 42.0201C52.0327
         41.6333 52.25 41.1087 52.25 40.5617C52.25 40.0147 52.0327 39.4901 51.6459 39.1033C51.2591 38.7165 50.7345 38.4992 50.1875 38.4992H44.6875ZM12.375 19.9367C12.375 19.3897 12.5923
          18.8651 12.9791 18.4783C13.3659 18.0915 13.8905 17.8742 14.4375 17.8742H23.0395L26.3175 13.7492H14.4375C12.7965 13.7492 11.2227 14.4011 10.0623 15.5614C8.9019 16.7218 8.25 18.2956
           8.25 19.9367V48.8117C8.25 51.182 9.19163 53.4553 10.8677 55.1314C12.5438 56.8075 14.8171 57.7492 17.1875 57.7492H50.1875C52.5579 57.7492 54.8312 56.8075 56.5073 55.1314C58.1834
            53.4553 59.125 51.182 59.125 48.8117V30.9367C59.125 28.5663 58.1834 26.293 56.5073 24.6169C54.8312 22.9408 52.5579 21.9992 50.1875 21.9992H14.4375C13.8905 21.9992 13.3659
            21.7819 12.9791 21.3951C12.5923 21.0083 12.375 20.4837 12.375 19.9367ZM12.375 48.8117V25.7722C13.0212 26.0004 13.7143 26.1242 14.4375 26.1242H50.1875C52.844 26.1242 55 28.2802
            55 30.9367V48.8117C55 50.088 54.493 51.3121 53.5905 52.2146C52.6879 53.1171 51.4639 53.6242 50.1875 53.6242H17.1875C15.9111 53.6242 14.6871 53.1171 13.7845 52.2146C12.882
             51.3121 12.375 50.088 12.375 48.8117Z" :fill="color"/>
    </svg>

    <svg v-if="name === 'open-wallet'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.66406 10.6693H29.3307M2.66406 8.0026C2.66406 7.29536 2.94501 6.61708 3.44511 6.11699C3.94521 5.61689 4.62349 5.33594 5.33073 5.33594H26.6641C27.3713 5.33594 28.0496
      5.61689 28.5497 6.11699C29.0498 6.61708 29.3307 7.29536 29.3307 8.0026V24.0026C29.3307 24.7098 29.0498 25.3881 28.5497 25.8882C28.0496 26.3883 27.3713 26.6693 26.6641
      26.6693H5.33073C4.62349 26.6693 3.94521 26.3883 3.44511 25.8882C2.94501 25.3881 2.66406 24.7098 2.66406 24.0026V8.0026Z" :stroke="color" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"/>
      <path d="M2.66406 16H11.9974C11.9974 17.3333 12.7974 20 15.9974 20C19.1974 20 19.9974 17.3333 19.9974 16H29.3307" :stroke="color" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg v-if="name === 'closed-wallet'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9167 11.4583C12.9167 11.2926 12.9825 11.1336 13.0997 11.0164C13.2169 10.8992 13.3759 10.8333 13.5417 10.8333H15.2083C15.3741 10.8333 15.5331 10.8992 15.6503
       11.0164C15.7675 11.1336 15.8333 11.2926 15.8333 11.4583C15.8333 11.6241 15.7675 11.7831 15.6503 11.9003C15.5331 12.0175 15.3741 12.0833 15.2083 12.0833H13.5417C13.3759
        12.0833 13.2169 12.0175 13.0997 11.9003C12.9825 11.7831 12.9167 11.6241 12.9167 11.4583ZM2.5 4.16667H2.51167C2.56291 3.70832 2.78128 3.28495 3.12504 2.97748C3.46879 2.67001
        3.9138 2.50001 4.375 2.5H13.9583C14.2046 2.5 14.4484 2.5485 14.6759 2.64273C14.9034 2.73695 15.11 2.87506 15.2842 3.04917C15.4583 3.22328 15.5964 3.42998 15.6906 3.65747C15.7848
        3.88495 15.8333 4.12877 15.8333 4.375V5.0725C16.4265 5.21334 16.9549 5.55015 17.3329 6.02847C17.711 6.50679 17.9167 7.09865 17.9167 7.70833V14.7917C17.9167 15.51 17.6313 16.1988
         17.1234 16.7067C16.6155 17.2147 15.9266 17.5 15.2083 17.5H5.20833C4.49004 17.5 3.80116 17.2147 3.29325 16.7067C2.78534 16.1988 2.5 15.51 2.5 14.7917V4.16667ZM15.2083
         6.25H3.75V14.7917C3.75 15.5967 4.40333 16.25 5.20833 16.25H15.2083C15.5951 16.25 15.966 16.0964 16.2395 15.8229C16.513 15.5494 16.6667 15.1784 16.6667 14.7917V7.70833C16.6667
          7.32156 16.513 6.95063 16.2395 6.67714C15.966 6.40364 15.5951 6.25 15.2083 6.25V6.25ZM14.5833 5V4.375C14.5833 4.20924 14.5175 4.05027 14.4003 3.93306C14.2831 3.81585 14.1241
          3.75 13.9583 3.75H4.375C4.20924 3.75 4.05027 3.81585 3.93306 3.93306C3.81585 4.05027 3.75 4.20924 3.75 4.375C3.75 4.54076 3.81585 4.69973 3.93306 4.81694C4.05027 4.93415
          4.20924 5 4.375 5H14.5833Z" :fill="color"/>
    </svg>




    <!--    <svg  v-if="name === 'to-and-fro'" width="14" :height="height" viewBox="0 0 14 80" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--      <line x1="7" y1="21" x2="7" y2="61" :stroke="color" stroke-width="2" stroke-dasharray="4 4"/>-->
<!--      <path d="M7 0.875C5.72411 0.876505 4.50091 1.38402 3.59872 2.28621C2.69652 3.1884 2.18901 4.41161 2.18751 5.6875C2.18598 6.73016 2.52656 7.74453 3.15701 8.575C3.15701 8.575 3.28826 8.74781 3.30969 8.77275L7 13.125L10.6921 8.77056C10.7113 8.74738 10.843 8.575 10.843 8.575L10.8434 8.57369C11.4736 7.74358 11.814 6.72968 11.8125 5.6875C11.811 4.41161 11.3035 3.1884 10.4013 2.28621C9.4991 1.38402 8.2759 0.876505 7 0.875ZM7 7.4375C6.65389 7.4375 6.31554 7.33486 6.02776 7.14257C5.73997 6.95028 5.51567 6.67697 5.38322 6.3572C5.25076 6.03743 5.21611 5.68556 5.28363 5.34609C5.35115 5.00663 5.51783 4.6948 5.76257 4.45006C6.00731 4.20532 6.31913 4.03865 6.6586 3.97113C6.99806 3.9036 7.34993 3.93826 7.6697 4.07071C7.98947 4.20316 8.26278 4.42747 8.45508 4.71525C8.64737 5.00304 8.75 5.34138 8.75 5.6875C8.74943 6.15145 8.56487 6.59623 8.2368 6.9243C7.90874 7.25236 7.46396 7.43692 7 7.4375Z"-->
<!--            :fill="color"/>-->
<!--      <circle cx="8" cy="74" r="3" :fill="color"/>-->
<!--      <path d="M14 74C14 77.3137 11.3137 80 8 80C4.68629 80 2 77.3137 2 74C2 70.6863 4.68629 68 8 68C11.3137 68 14 70.6863 14 74ZM3.0671 74C3.0671 76.7244 5.27563 78.9329 8 78.9329C10.7244 78.9329 12.9329 76.7244 12.9329 74C12.9329 71.2756 10.7244 69.0671 8 69.0671C5.27563 69.0671 3.0671 71.2756 3.0671 74Z" :fill="color"/>-->
<!--    </svg>-->



  </div>
</template>

<script>
export default {
  name: "UsedIcons",
  props: {
    name: [String],
    color: {
      type: [String],
      default: "#004AAD",
    },
    width: [String],
    height: [String]
  },
};
</script>

<style scoped></style>
