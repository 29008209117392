/* eslint-disable no-unused-vars */
import callEndpoint from "@/services/api";
import METHODS from "@/services/api/apiMethods";
import axios from "axios";
import authHeader from "@/services/api/auth-header";
import {
  data
} from "autoprefixer";

let RESOURCE_URL = "";

if (process.env.NODE_ENV === "development") {
  RESOURCE_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
} else {
  RESOURCE_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
}
const {
  POST,
  //     PUT,
  PATCH,
  DELETE,
} = METHODS;

export const uploadToCloudinary = async (data) => {

  return axios.post(process.env.VUE_APP_CLOUDINARY_URL, data);
};
// https://res.cloudinary.com/demo/image/upload/f_auto,q_auto,e_trim,b_white/bo_1px_solid_gray/docs/rmv_bgd/stuffed.jpg
// Get countries and their states
const COUNTRY_BASE_URL = "https://countriesnow.space/api/v0.1/countries/states";

const CITY_BASE_URL =
  "https://countriesnow.space/api/v0.1/countries/state/cities";

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};
const header = {
  'Content-Type': 'application/json',
  'Authorization': 'dskjdks',
  'sandbox-key': 'NrfBD4sIVcg7eGkAhlvVy1BWu6oEArIU1650754889'
}

export const getAllCountries = async () => {
  return axios.get(COUNTRY_BASE_URL, {
    headers
  });
};
export const getAllStatesByCountry = async (country) => {
  return axios.post(COUNTRY_BASE_URL, country, {
    headers
  });
};

export const getAllCitiesInAState = async (data) => {
  return axios.post(CITY_BASE_URL, data, {
    headers
  });
};
export const getAllBanks = async () => {
  const url = 'https://fsi.ng/api/v1/flutterwave/v3/banks/NG?country=NG'
  return axios.get(url, {
    headers: header
  })
}

export const getLatLong = async (data) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${data.houseNumber}+${data.streetName}+${data.city},+${data.state},+${data.country}
  &key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}`;
  return axios.get(url);
};


export const customerOnBoarding = (data) => {
  const url = "/api/customer/update-account"
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
};


export const getCompanyData = async (companyId) => {
  const url = `/api/transport-company/get/${companyId}`;
  return callEndpoint({
    url,
    needsAuth: true
  });
};

export const getPassengerDetail = async (bookingCode)  =>{
  const url = `/api/booking/booking-code/get-traveller-details-by-code/${bookingCode}`
  return callEndpoint({url,needsAuth: true})
}

export const getMobileAgentApplicationUserId = async (userId)=>{
  const  url = `/api/mobile-agent/get-by-user-id/${userId}`
  return callEndpoint({url})
}

export const getAllTransportCompanies = async (uniqueIdentifier) => {
  const  url = `/api/agent/transport-company/get-all/${uniqueIdentifier}`
  return callEndpoint({url})
}

export  const subscribeToTransportCompanies = async (data) =>{
  const  url = `/api/agent/transport-company/subscribe-to-a-transport-company/${data.mobileAgentId}`
  return callEndpoint({method:PATCH,url,data})
}

export  const unsubscribeToTransportCompanies = async (data) =>{
  const  url = `/api/agent/transport-company/unsubscribe-to-a-transport-company/${data.mobileAgentId}`
  return callEndpoint({method:PATCH,url,data})
}

export const getAllTripsOfTransportCompaniesSubscribedTo = async (mobileAgentId) =>{
  const  url = `/api/agent/trip/get-all-trips-of-transport-companies-subscribed-to/${mobileAgentId}`
  return callEndpoint({url})
}

export const getOneTripOfTransportCompanySubscribedTo = async (data) =>{
  const  url = `/api/agent/trip/get-one-trip/${data.mobileAgentId}/${data.transportCompanyId}/${data.tripId}`
  return callEndpoint({url})
}

export const reservedSeat = async (data) =>{
  const url = `/api/customer-trip/reserve-seat/${data.tripId}`
  return callEndpoint({method:PATCH,url,data})
}

export const unreservedSeat = async (data) =>{
  const  url = `/api/customer-trip/un-reserve-seat/${data.tripId}`
  return callEndpoint({method:PATCH, url,data})
}

export const bookTrip = async (data) =>{
  const url = `/api/agent-booking/new-booking/${data.mobileAgentId}/${data.tripId}`
  return callEndpoint({method:POST,url,data})
}

export const getAllBookings = async (individualAgentId) =>{
  const url = `/api/agent-booking/get-all-bookings/${individualAgentId}`
  return callEndpoint({url})
}

export const getDailyBookings = async (mobileAgentId) =>{
  const url = `/api/agent-booking/get-daily-bookings/${mobileAgentId}`
  return callEndpoint({url})
}

export const getDailySales = async (mobileAgentId) =>{
  const url = `/api/agent-booking/get-daily-sales/${mobileAgentId}`
  return callEndpoint({url})
}

export const getWalletByUserEmail = async (email) =>{
  const url = `/api/wallet/get-wallet-by-user-email/${email}`
  return callEndpoint({url})
}

export const getWalletBalanceByUserEmail = async (email) =>{
  const url = `/api/wallet/get-wallet-balance/${email}`
  return callEndpoint({url})
}

export const getPaystackAccountDetails = async (email) =>{
  const url = `/api/paystack/get-account-by-user-email/${email}`
  return callEndpoint({url})
}

export const verifyPaystackPayment = async  (userId, reference) =>{
  const  url = `/api/paystack/verify-payment/${userId}/${reference}`
  return callEndpoint({method:PATCH,url})
}

export  const withdrawFromWallet = async (data) =>{
  const url = `/api/wallet/transfer-funds-from-wallet`
  return callEndpoint({method:PATCH,url,data})
}

export const verifyPassengerDetailExist = async (detail) => {
  const url = `/api/booking/booking-code/verify-traveller-details-exist/${detail}`
  return callEndpoint({url})
}
export const getBookingCodeByPhoneNumber = async (phoneNumber) => {
  const url = `/api/booking/booking-code/get-booking-code-by-phone-number/${phoneNumber}`
  return callEndpoint({
    url
  })
}

export const sendOtpToPassenger = async (data) => {
  const url = `https://api.ng.termii.com/api/sms/otp/send`
  return axios.post(url, data)
}

export const verifyPassengerOtp = async (data) => {
  const url = `https://api.ng.termii.com/api/sms/otp/verify`
  return axios.post(url, data)
}

export const addBooking = async (data) => {
  const url = `/api/booking/add-booking/${data.tripId}`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}


const paystackHeaders = {
  'Content-Type': 'application/json',
  "Authorization":  'Bearer '+process.env.VUE_APP_PAYSTACK_LIVE_SECRET_KEY

};

export const  makePayment = async  (data) =>{
  const url = `https://api.paystack.co/transaction/initialize`
  return axios.post(url,data,{headers:paystackHeaders})
}

export const authorizePayment = async (data) =>{
  return axios.post(data.url,data,{headers:paystackHeaders})
}

export const verifyPayment = async (ref) =>{
  const  url = `https://api.paystack.co/transaction/verify/${ref}`
  return axios.get(url,{headers:paystackHeaders})
}